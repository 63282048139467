import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AccountReceivableByCustomerIdResponse = {
  __typename?: 'AccountReceivableByCustomerIdResponse';
  accountReceivables: Array<AccountReceivableResponse>;
  current: Scalars['Int'];
  past: Scalars['Int'];
};

export type AccountReceivableResponse = {
  __typename?: 'AccountReceivableResponse';
  id: Scalars['ID'];
  type: AccountReceivableType;
  cash: Scalars['Int'];
  creditCard: Scalars['Int'];
  deposit: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  eventDate: Scalars['DateTime'];
  host?: Maybe<HostResponse>;
  customer?: Maybe<CustomerResponse>;
  order?: Maybe<OrderResponse>;
};

export type AccountReceivableSumResponse = {
  __typename?: 'AccountReceivableSumResponse';
  id: Scalars['ID'];
  type: AccountReceivableType;
  cash: Scalars['Int'];
  creditCard: Scalars['Int'];
  deposit: Scalars['Int'];
  eventDate: Scalars['DateTime'];
  host?: Maybe<HostResponse>;
  customer?: Maybe<CustomerResponse>;
  order?: Maybe<OrderResponse>;
  current: Scalars['Int'];
  past: Scalars['Int'];
};

/** The type of AccountReceivableType */
export enum AccountReceivableType {
  ACCOUNT_RECEIVABLE = 'ACCOUNT_RECEIVABLE',
  DEPOSIT = 'DEPOSIT'
}

export type AddItemToOrderInput = {
  orderId: Scalars['String'];
  itemId: Scalars['String'];
  quantity: Scalars['Int'];
  status: OrderItemStatus;
  pageIndex: Scalars['Int'];
};

export type AddStoreTablesToDeviceInput = {
  storeTableIds: Array<Scalars['String']>;
  removeStoreTableIds: Array<Scalars['String']>;
};

export type AddUniqueItemToOrderInput = {
  orderId: Scalars['String'];
  itemName: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type AdminLoginResponse = {
  __typename?: 'AdminLoginResponse';
  token: Scalars['String'];
  isMaster: Scalars['Boolean'];
  isCollector: Scalars['Boolean'];
  adminName: Scalars['String'];
  type: AdminType;
};

export type AdminResponse = {
  __typename?: 'AdminResponse';
  id: Scalars['ID'];
  account: Scalars['String'];
  isMaster: Scalars['Boolean'];
  isCollector: Scalars['Boolean'];
  storeId?: Maybe<Scalars['String']>;
  store?: Maybe<StoreResponse>;
  type?: Maybe<AdminType>;
};

/** The type of AdminType */
export enum AdminType {
  MASTER = 'MASTER',
  ADMIN = 'ADMIN',
  COLLECTOR = 'COLLECTOR'
}

export type AggregateDepositResponse = {
  __typename?: 'AggregateDepositResponse';
  depositRate: Scalars['Int'];
  unpaidAmount: Scalars['Int'];
};

export type AggregatePenaltyResponse = {
  __typename?: 'AggregatePenaltyResponse';
  attendance: Scalars['Int'];
  store: Scalars['Int'];
};

export type AggregateResponse = {
  __typename?: 'AggregateResponse';
  host: HostResponse;
  aggregateSales: AggregateSalesResponse;
  totalOrders: Scalars['Int'];
  totalNewCustomer: Scalars['Int'];
  aggregateDeposit: AggregateDepositResponse;
  aggregatePenalty: AggregatePenaltyResponse;
};

export type AggregateSalesResponse = {
  __typename?: 'AggregateSalesResponse';
  provisionalKGSales: Scalars['Int'];
  finalKGSales: Scalars['Int'];
  provisionalStoreSales: Scalars['Int'];
  finalStoreSales: Scalars['Int'];
  totalSales: Scalars['Int'];
  totalNominates: Scalars['Int'];
  totalBottleBackPrice: Scalars['Int'];
};

export type BulkUpdateItemsInput = {
  category?: Maybe<ItemCategory>;
  price?: Maybe<Scalars['Int']>;
  sortNo?: Maybe<Scalars['Int']>;
  bottleBack?: Maybe<Scalars['Int']>;
  accessControl?: Maybe<Array<DeviceType>>;
};

export type CashCollectionResponse = {
  __typename?: 'CashCollectionResponse';
  id: Scalars['ID'];
  eventDate: Scalars['DateTime'];
  itemName: Scalars['String'];
  amount: Scalars['Int'];
  hostName?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  storeId?: Maybe<Scalars['String']>;
  store?: Maybe<StoreResponse>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CashCollectionSumResponse = {
  __typename?: 'CashCollectionSumResponse';
  cashCollections?: Maybe<Array<CashCollectionResponse>>;
  currentSum: Scalars['Int'];
};

export type CreateAdminInput = {
  storeId: Scalars['String'];
  account: Scalars['String'];
  password: Scalars['String'];
  isCollector?: Maybe<Scalars['Boolean']>;
};

export type CreateCashCollectionInput = {
  eventDate: Scalars['DateTime'];
  itemName: Scalars['String'];
  amount: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  storeId: Scalars['String'];
};

export type CreateCustomerInput = {
  name: Scalars['String'];
  storeId: Scalars['String'];
  hostId?: Maybe<Scalars['String']>;
};

export type CreateDepositAccountReceivableInput = {
  customerId: Scalars['String'];
  cash: Scalars['Int'];
  creditCard: Scalars['Int'];
  deposit: Scalars['Int'];
  targetMonth: Scalars['DateTime'];
  depositDate: Scalars['DateTime'];
};

export type CreateDeviceInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: DeviceType;
  account: Scalars['String'];
  password: Scalars['String'];
  storeId: Scalars['String'];
};

export type CreateExpenseInput = {
  price: Scalars['Int'];
  eventDate: Scalars['DateTime'];
  type: ExpenseType;
  shopName?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  expenseItemId?: Maybe<Scalars['String']>;
};

export type CreateExpenseItemInput = {
  name: Scalars['String'];
  examples?: Maybe<Scalars['String']>;
  sortNo: Scalars['Int'];
};

export type CreateHostInput = {
  storeId: Scalars['String'];
  name: Scalars['String'];
  yomi?: Maybe<Scalars['String']>;
  hostIsNumber: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  titleId?: Maybe<Scalars['String']>;
  sortNo: Scalars['Int'];
};

export type CreateHostPenaltyInput = {
  penaltyDate: Scalars['DateTime'];
  hostId: Scalars['String'];
  penaltyId: Scalars['String'];
  price: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};

export type CreateInformationCenterInput = {
  storeId: Scalars['String'];
  name: Scalars['String'];
  sortNo: Scalars['Int'];
};

export type CreateInitialInput = {
  name: Scalars['String'];
  price: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  storeId: Scalars['String'];
};

export type CreateItemInput = {
  storeId: Scalars['String'];
  name: Scalars['String'];
  isFreeDrink: Scalars['Boolean'];
  category: ItemCategory;
  price: Scalars['Int'];
  sortNo: Scalars['Int'];
  bottleBack: Scalars['Int'];
  accessControl: Array<DeviceType>;
};

export type CreateOrderInput = {
  hostId?: Maybe<Scalars['String']>;
  initialId?: Maybe<Scalars['String']>;
  initialCount?: Maybe<Scalars['Int']>;
  storeTableId: Scalars['String'];
  customerName?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  course: OrderCourse;
};

export type CreateOrderItemHistoryInput = {
  orderHistoryId: Scalars['String'];
  orderItemId: Scalars['String'];
  itemName: Scalars['String'];
  itemPrice: Scalars['Int'];
};

export type CreateOrderPaymentInput = {
  orderId: Scalars['String'];
  cash: Scalars['Int'];
  creditCard: Scalars['Int'];
  accountReceivable: Scalars['Int'];
  tipping: Scalars['Int'];
  optionalAmount: Scalars['Int'];
  hasReceipt?: Maybe<Scalars['Boolean']>;
};

export type CreatePenaltyInput = {
  storeId: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
};

export type CreateStoreInput = {
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  receiptPrinterIpAddress?: Maybe<Scalars['String']>;
  serviceFee: Scalars['Int'];
  orderItemLockTime?: Maybe<Scalars['String']>;
  staffReservationFee?: Maybe<Scalars['Int']>;
};

export type CreateStoreTableInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  storeId: Scalars['String'];
};

export type CreateTitleInput = {
  name: Scalars['String'];
  sortNo: Scalars['Int'];
};

export type CustomerFileResponse = {
  __typename?: 'CustomerFileResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  extension: Scalars['String'];
  customer?: Maybe<CustomerResponse>;
  signedUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type CustomerResponse = {
  __typename?: 'CustomerResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  hostName: Scalars['String'];
  hostId?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  arrivalCount?: Maybe<Scalars['Int']>;
  lastArrivalTime?: Maybe<Scalars['DateTime']>;
  host?: Maybe<HostResponse>;
  orders?: Maybe<Array<OrderResponse>>;
  accountReceivables?: Maybe<Array<AccountReceivableResponse>>;
  accountReceivableSum?: Maybe<Scalars['Int']>;
  lastPaymentSum?: Maybe<Scalars['Int']>;
  currentPaymentSum?: Maybe<Scalars['Int']>;
};

export type DailyReportResponse = {
  __typename?: 'DailyReportResponse';
  id: Scalars['ID'];
  week: Scalars['Int'];
  businessDate: Scalars['String'];
  cashSales: Scalars['Int'];
  creditCardSales: Scalars['Int'];
  cashCollection: Scalars['Int'];
  cashDeposit: Scalars['Int'];
  creditCardDeposit: Scalars['Int'];
  transferDeposit: Scalars['Int'];
  expenseUsed: Scalars['Int'];
  expenseDeposit: Scalars['Int'];
  expenseBalance: Scalars['Int'];
};


export type DeviceLoginResponse = {
  __typename?: 'DeviceLoginResponse';
  token: Scalars['String'];
  storeId: Scalars['String'];
  storeName: Scalars['String'];
  deviceId: Scalars['String'];
  deviceName: Scalars['String'];
  type: DeviceType;
};

export type DeviceResponse = {
  __typename?: 'DeviceResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: DeviceType;
  account: Scalars['String'];
  isOrderItemLockDisabled?: Maybe<Scalars['Boolean']>;
  storeTables?: Maybe<Array<StoreTableResponse>>;
  storeId?: Maybe<Scalars['String']>;
};

/** The type of DeviceType */
export enum DeviceType {
  CASHIER = 'CASHIER',
  HALL = 'HALL',
  VIEWER = 'VIEWER',
  KITCHEN = 'KITCHEN',
  OFFICE_STAFF = 'OFFICE_STAFF'
}

export type ExpenseItemResponse = {
  __typename?: 'ExpenseItemResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  examples?: Maybe<Scalars['String']>;
  sortNo: Scalars['Int'];
};

export type ExpenseResponse = {
  __typename?: 'ExpenseResponse';
  id: Scalars['ID'];
  eventDate: Scalars['DateTime'];
  type: ExpenseType;
  price: Scalars['Int'];
  shopName?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  store?: Maybe<StoreResponse>;
  expenseItem?: Maybe<ExpenseItemResponse>;
};

/** The type of ExpenseType */
export enum ExpenseType {
  CONSUME = 'CONSUME',
  DEPOSIT = 'DEPOSIT'
}

export type HostPenaltyResponse = {
  __typename?: 'HostPenaltyResponse';
  id: Scalars['ID'];
  penaltyDate: Scalars['DateTime'];
  price: Scalars['Int'];
  host: HostResponse;
  penalty: PenaltyResponse;
  description?: Maybe<Scalars['String']>;
  storeName?: Maybe<Scalars['String']>;
};

export type HostResponse = {
  __typename?: 'HostResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  yomi?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  hostIsNumber: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTime']>;
  status: HostStatus;
  title?: Maybe<TitleResponse>;
  store?: Maybe<StoreResponse>;
  sortNo: Scalars['Int'];
  sales?: Maybe<Scalars['Int']>;
  appoints?: Maybe<Scalars['Int']>;
};

/** The type of HostStatus */
export enum HostStatus {
  ACTIVE = 'ACTIVE',
  LEAVING = 'LEAVING',
  RESIGNED = 'RESIGNED'
}

export type InformationCenterResponse = {
  __typename?: 'InformationCenterResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  sortNo: Scalars['Int'];
  storeId?: Maybe<Scalars['String']>;
};

export type InitialResponse = {
  __typename?: 'InitialResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
};

export enum ItemCategory {
  CAN_BOTTLE = 'CAN_BOTTLE',
  NIHONSHU = 'NIHONSHU',
  SHOCHU = 'SHOCHU',
  COCKTAIL = 'COCKTAIL',
  LIQUEUR = 'LIQUEUR',
  SHOT = 'SHOT',
  SOFT_DRINK = 'SOFT_DRINK',
  CHAMPAGNE_BRANDY = 'CHAMPAGNE_BRANDY',
  CHAMPAGNE = 'CHAMPAGNE',
  BRANDY = 'BRANDY',
  WINE = 'WINE',
  FREE_DRINK = 'FREE_DRINK',
  FOOD = 'FOOD',
  INSIDE_CALL = 'INSIDE_CALL',
  OTHER = 'OTHER',
  BASIC_CHARGE = 'BASIC_CHARGE',
  FIRST_TIME_ORDER = 'FIRST_TIME_ORDER',
  UNIQUE_ORDER = 'UNIQUE_ORDER'
}

export type ItemResponse = {
  __typename?: 'ItemResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: ItemCategory;
  price: Scalars['Int'];
  host?: Maybe<HostResponse>;
  sortNo: Scalars['Int'];
  bottleBack?: Maybe<Scalars['Int']>;
  accessControl?: Maybe<Array<DeviceType>>;
  storeId?: Maybe<Scalars['String']>;
};

export type Lastsong = {
  __typename?: 'Lastsong';
  businessDate: Scalars['String'];
  hostId: Scalars['String'];
  hostName: Scalars['String'];
  sales: Scalars['Int'];
  appoints: Scalars['Int'];
};

export type LastsongResponse = {
  __typename?: 'LastsongResponse';
  dailyTopSales: Array<Array<Lastsong>>;
  monthlyTopSales: Array<Lastsong>;
  dailyTopAppoints: Array<Array<Lastsong>>;
  monthlyTopAppoints: Array<Lastsong>;
};

export type LoginInput = {
  account: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addItemToOrder: Scalars['String'];
  addMultipleItemsToOrder: Array<Scalars['String']>;
  addUniqueItemToOrder: Scalars['String'];
  updateItemOrderStatus: Scalars['String'];
  updateItemOrder: Scalars['String'];
  updateIsUsedForLastsong: Scalars['Boolean'];
  updateIsKitchenChecked: Scalars['Boolean'];
  createItem: Scalars['String'];
  updateItem: Scalars['String'];
  bulkUpdateItems: Scalars['Float'];
  deleteItem: Scalars['Boolean'];
  createOrder: Scalars['String'];
  cancelOrder: Scalars['String'];
  updateOrder: Scalars['String'];
  tentativeDeleteOrder: Scalars['String'];
  customersByName: Array<CustomerResponse>;
  customersByNameAndHostId: Array<CustomerResponse>;
  createCustomer: Scalars['String'];
  updateCustomer: Scalars['String'];
  deleteCustomer: Scalars['Boolean'];
  mergeCustomers: CustomerResponse;
  createStore: Scalars['String'];
  updateStore: Scalars['String'];
  deleteStore: Scalars['Boolean'];
  updateIsOrderItemLocked: Scalars['Boolean'];
  createHost: Scalars['String'];
  updateHost: Scalars['String'];
  deleteHost: Scalars['Boolean'];
  createTitle: Scalars['String'];
  updateTitle: Scalars['String'];
  deleteTitle: Scalars['Boolean'];
  createOrderPayment: Scalars['String'];
  updateOrderPayment: Scalars['String'];
  createDepositAccountReceivable: Scalars['String'];
  updateDepositAccountReceivable: Scalars['String'];
  deleteCustomerFile: Scalars['String'];
  createOrderItemHistory: Scalars['String'];
  createDevice: Scalars['String'];
  updateDevice: Scalars['String'];
  addStoreTablesToDevice: Scalars['String'];
  deleteDevice: Scalars['Boolean'];
  updateIsOrderItemLockDisabled: Scalars['Boolean'];
  createPenalty: Scalars['String'];
  updatePenalty: Scalars['String'];
  deletePenalty: Scalars['Boolean'];
  createExpense: Scalars['String'];
  updateExpense: Scalars['String'];
  deleteExpense: Scalars['Boolean'];
  createExpenseItem: Scalars['String'];
  updateExpenseItem: Scalars['String'];
  deleteExpenseItem: Scalars['Boolean'];
  createHostPenalty: Scalars['String'];
  updateHostPenalty: Scalars['String'];
  deleteHostPenalty: Scalars['Boolean'];
  createStoreTable: Scalars['String'];
  updateStoreTable: Scalars['String'];
  deleteStoreTable: Scalars['Boolean'];
  createAdmin: Scalars['String'];
  updateAdmin: Scalars['String'];
  deleteAdmin: Scalars['Boolean'];
  createInitial: Scalars['String'];
  updateInitial: Scalars['String'];
  deleteInitial: Scalars['Boolean'];
  adminLogin: AdminLoginResponse;
  deviceLogin: DeviceLoginResponse;
  createCashCollection: Scalars['String'];
  updateCashCollection: Scalars['String'];
  lockCashCollection: Scalars['String'];
  deleteCashCollection: Scalars['Boolean'];
  createInformationCenter: Scalars['String'];
  updateInformationCenter: Scalars['String'];
  deleteInformationCenter: Scalars['Boolean'];
};


export type MutationAddItemToOrderArgs = {
  input: AddItemToOrderInput;
};


export type MutationAddMultipleItemsToOrderArgs = {
  inputs: Array<AddItemToOrderInput>;
};


export type MutationAddUniqueItemToOrderArgs = {
  input: AddUniqueItemToOrderInput;
};


export type MutationUpdateItemOrderStatusArgs = {
  input: UpdateItemOrderStatusInput;
  id: Scalars['String'];
};


export type MutationUpdateItemOrderArgs = {
  input: UpdateItemOrderInput;
  id: Scalars['String'];
};


export type MutationUpdateIsUsedForLastsongArgs = {
  input: UpdateIsUsedForLastsongInput;
};


export type MutationUpdateIsKitchenCheckedArgs = {
  input: UpdateIsKitchenCheckedInput;
};


export type MutationCreateItemArgs = {
  input: CreateItemInput;
};


export type MutationUpdateItemArgs = {
  input: UpdateItemInput;
  id: Scalars['String'];
};


export type MutationBulkUpdateItemsArgs = {
  input: BulkUpdateItemsInput;
  ids: Array<Scalars['String']>;
};


export type MutationDeleteItemArgs = {
  id: Scalars['String'];
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCancelOrderArgs = {
  reasonForCancel?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
  id: Scalars['String'];
};


export type MutationTentativeDeleteOrderArgs = {
  input: TentativeDeleteOrderInput;
};


export type MutationCustomersByNameArgs = {
  name: Scalars['String'];
};


export type MutationCustomersByNameAndHostIdArgs = {
  hostId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
  id: Scalars['String'];
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['String'];
};


export type MutationMergeCustomersArgs = {
  childId: Scalars['String'];
  parentId: Scalars['String'];
};


export type MutationCreateStoreArgs = {
  input: CreateStoreInput;
};


export type MutationUpdateStoreArgs = {
  input: UpdateStoreInput;
  id: Scalars['String'];
};


export type MutationDeleteStoreArgs = {
  id: Scalars['String'];
};


export type MutationUpdateIsOrderItemLockedArgs = {
  input: UpdateIsOrderItemLockedInput;
};


export type MutationCreateHostArgs = {
  input: CreateHostInput;
};


export type MutationUpdateHostArgs = {
  input: UpdateHostInput;
  id: Scalars['String'];
};


export type MutationDeleteHostArgs = {
  id: Scalars['String'];
};


export type MutationCreateTitleArgs = {
  input: CreateTitleInput;
};


export type MutationUpdateTitleArgs = {
  input: UpdateTitleInput;
  id: Scalars['String'];
};


export type MutationDeleteTitleArgs = {
  id: Scalars['String'];
};


export type MutationCreateOrderPaymentArgs = {
  input: CreateOrderPaymentInput;
};


export type MutationUpdateOrderPaymentArgs = {
  input: UpdateOrderPaymentInput;
  id: Scalars['String'];
};


export type MutationCreateDepositAccountReceivableArgs = {
  input: CreateDepositAccountReceivableInput;
};


export type MutationUpdateDepositAccountReceivableArgs = {
  input: UpdateDepositAccountReceivableInput;
  id: Scalars['String'];
};


export type MutationDeleteCustomerFileArgs = {
  id: Scalars['String'];
};


export type MutationCreateOrderItemHistoryArgs = {
  input: CreateOrderItemHistoryInput;
};


export type MutationCreateDeviceArgs = {
  input: CreateDeviceInput;
};


export type MutationUpdateDeviceArgs = {
  input: UpdateDeviceInput;
  id: Scalars['String'];
};


export type MutationAddStoreTablesToDeviceArgs = {
  input: AddStoreTablesToDeviceInput;
  id: Scalars['String'];
};


export type MutationDeleteDeviceArgs = {
  id: Scalars['String'];
};


export type MutationUpdateIsOrderItemLockDisabledArgs = {
  input: UpdateIsOrderItemLockDisabledInput;
};


export type MutationCreatePenaltyArgs = {
  input: CreatePenaltyInput;
};


export type MutationUpdatePenaltyArgs = {
  input: UpdatePenaltyInput;
  id: Scalars['String'];
};


export type MutationDeletePenaltyArgs = {
  id: Scalars['String'];
};


export type MutationCreateExpenseArgs = {
  storeId?: Maybe<Scalars['String']>;
  input: CreateExpenseInput;
};


export type MutationUpdateExpenseArgs = {
  input: UpdateExpenseInput;
  id: Scalars['String'];
};


export type MutationDeleteExpenseArgs = {
  id: Scalars['String'];
};


export type MutationCreateExpenseItemArgs = {
  input: CreateExpenseItemInput;
};


export type MutationUpdateExpenseItemArgs = {
  input: UpdateExpenseItemInput;
  id: Scalars['String'];
};


export type MutationDeleteExpenseItemArgs = {
  id: Scalars['String'];
};


export type MutationCreateHostPenaltyArgs = {
  input: CreateHostPenaltyInput;
};


export type MutationUpdateHostPenaltyArgs = {
  input: UpdateHostPenaltyInput;
  id: Scalars['String'];
};


export type MutationDeleteHostPenaltyArgs = {
  id: Scalars['String'];
};


export type MutationCreateStoreTableArgs = {
  input: CreateStoreTableInput;
};


export type MutationUpdateStoreTableArgs = {
  input: UpdateStoreTableInput;
  id: Scalars['String'];
};


export type MutationDeleteStoreTableArgs = {
  id: Scalars['String'];
};


export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};


export type MutationUpdateAdminArgs = {
  input: UpdateAdminInput;
};


export type MutationDeleteAdminArgs = {
  id: Scalars['String'];
};


export type MutationCreateInitialArgs = {
  input: CreateInitialInput;
};


export type MutationUpdateInitialArgs = {
  input: UpdateInitialInput;
  id: Scalars['String'];
};


export type MutationDeleteInitialArgs = {
  id: Scalars['String'];
};


export type MutationAdminLoginArgs = {
  input: LoginInput;
};


export type MutationDeviceLoginArgs = {
  input: LoginInput;
};


export type MutationCreateCashCollectionArgs = {
  input: CreateCashCollectionInput;
};


export type MutationUpdateCashCollectionArgs = {
  input: UpdateCashCollectionInput;
  id: Scalars['String'];
};


export type MutationLockCashCollectionArgs = {
  isLocked: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationDeleteCashCollectionArgs = {
  id: Scalars['String'];
};


export type MutationCreateInformationCenterArgs = {
  input: CreateInformationCenterInput;
};


export type MutationUpdateInformationCenterArgs = {
  input: UpdateInformationCenterInput;
  id: Scalars['String'];
};


export type MutationDeleteInformationCenterArgs = {
  id: Scalars['String'];
};

export type OrderAmountResponse = {
  __typename?: 'OrderAmountResponse';
  subtotal: Scalars['Int'];
  adjustment: Scalars['Int'];
  serviceFee: Scalars['Int'];
  tax: Scalars['Int'];
  total: Scalars['Int'];
  staffReservationFee: Scalars['Int'];
  initialPrice: Scalars['Int'];
  initialCount: Scalars['Int'];
};

/** The type of orderCourse */
export enum OrderCourse {
  FIRST_TIME = 'FIRST_TIME',
  NORMAL = 'NORMAL',
  COMPETITOR_NO_SALES = 'COMPETITOR_NO_SALES'
}

export type OrderHistoryResponse = {
  __typename?: 'OrderHistoryResponse';
  id: Scalars['ID'];
  course: OrderCourse;
  initialPrice: Scalars['Int'];
  initialCount: Scalars['Int'];
  storeServiceFee: Scalars['Int'];
  storeStaffReservationFee?: Maybe<Scalars['Int']>;
  status?: Maybe<OrderStatus>;
  hasReceipt: Scalars['Boolean'];
  cash: Scalars['Int'];
  creditCard: Scalars['Int'];
  accountReceivable: Scalars['Int'];
  tipping: Scalars['Int'];
  optionalAmount: Scalars['Int'];
  orderId: Scalars['String'];
  order?: Maybe<OrderResponse>;
  orderItems: Array<OrderItemResponse>;
  createdAt: Scalars['DateTime'];
  amount: Scalars['Int'];
};

export type OrderItemHistoryResponse = {
  __typename?: 'OrderItemHistoryResponse';
  id: Scalars['ID'];
  itemName: Scalars['String'];
  itemPrice: Scalars['Int'];
};

export type OrderItemResponse = {
  __typename?: 'OrderItemResponse';
  id: Scalars['String'];
  item: ItemResponse;
  order?: Maybe<OrderResponse>;
  quantity: Scalars['Int'];
  status: OrderItemStatus;
  pageIndex?: Maybe<Scalars['Int']>;
  isUsedForLastsong?: Maybe<Scalars['Boolean']>;
  isKitchenChecked?: Maybe<Scalars['Boolean']>;
  itemName?: Maybe<Scalars['String']>;
  itemPrice?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

/** The type of OrderItemStatus */
export enum OrderItemStatus {
  OPENED = 'OPENED',
  SERVED = 'SERVED',
  CANCELED = 'CANCELED'
}

export type OrderPaymentResponse = {
  __typename?: 'OrderPaymentResponse';
  id: Scalars['ID'];
  cash: Scalars['Int'];
  creditCard: Scalars['Int'];
  accountReceivable: Scalars['Int'];
  tipping: Scalars['Int'];
  optionalAmount: Scalars['Int'];
  hasReceipt: Scalars['Boolean'];
};

export type OrderResponse = {
  __typename?: 'OrderResponse';
  id: Scalars['ID'];
  course: OrderCourse;
  status: OrderStatus;
  host?: Maybe<HostResponse>;
  initial?: Maybe<InitialResponse>;
  customer?: Maybe<CustomerResponse>;
  orderPayment?: Maybe<OrderPaymentResponse>;
  storeTable?: Maybe<StoreTableResponse>;
  store?: Maybe<StoreResponse>;
  orderItems?: Maybe<Array<OrderItemResponse>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  initialCount?: Maybe<Scalars['Int']>;
  limitAmount?: Maybe<Scalars['Int']>;
  reasonForCancel?: Maybe<Scalars['String']>;
  dailyId?: Maybe<Scalars['Int']>;
  hasProgressItems: Scalars['Boolean'];
  hasKitchenUncheckedItems?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Int']>;
  currentAmount?: Maybe<OrderAmountResponse>;
  lastOrderedTime?: Maybe<Scalars['DateTime']>;
};

/** The type of OrderStatus */
export enum OrderStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED'
}

export type PenaltyResponse = {
  __typename?: 'PenaltyResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Int'];
  storeId?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  inventoriesByDevice: Array<OrderItemResponse>;
  itemsByStoreId: Array<ItemResponse>;
  itemsByDevice: Array<ItemResponse>;
  item: ItemResponse;
  ordersByStoreId: Array<OrderResponse>;
  orderById: OrderResponse;
  getAggregateOrdersByStoreId: Array<OrderResponse>;
  lastsong: LastsongResponse;
  customers: Array<CustomerResponse>;
  customersByStoreId: Array<CustomerResponse>;
  customersByDevice: Array<CustomerResponse>;
  customersByHostId: Array<CustomerResponse>;
  customerById: CustomerResponse;
  stores: Array<StoreResponse>;
  store: StoreResponse;
  getIsOrderItemLocked: Scalars['Boolean'];
  getOrderHistoriesByOrderId: Array<OrderHistoryResponse>;
  getLatestOrderHistoryByOrderId: OrderHistoryResponse;
  hostsByStoreId: Array<HostResponse>;
  hostsByDevice: Array<HostResponse>;
  hostsByTitleId: Array<HostResponse>;
  host: HostResponse;
  titles: Array<TitleResponse>;
  title: TitleResponse;
  accountReceivablesByDevice: Array<AccountReceivableSumResponse>;
  accountReceivablesByCustomerId: AccountReceivableByCustomerIdResponse;
  getCustomerFilesByCustomerId: Array<CustomerFileResponse>;
  getOrderItemHistory: Array<OrderItemHistoryResponse>;
  devicesByStoreId: Array<DeviceResponse>;
  devicesByDevice: Array<DeviceResponse>;
  device: DeviceResponse;
  penalties: Array<PenaltyResponse>;
  penaltiesByStoreId: Array<PenaltyResponse>;
  penalty: PenaltyResponse;
  expensesByStoreId: Array<ExpenseResponse>;
  expense: ExpenseResponse;
  expenseItems: Array<ExpenseItemResponse>;
  expenseItem: ExpenseItemResponse;
  hostPenaltiesByStoreId: Array<HostPenaltyResponse>;
  storeTablesByStoreId: Array<StoreTableResponse>;
  storeTablesByDevice: Array<StoreTableResponse>;
  storeTable: StoreTableResponse;
  adminsByStoreId: Array<AdminResponse>;
  adminById: AdminResponse;
  admin: AdminResponse;
  initialsByStoreId: Array<InitialResponse>;
  initialsByDevice: Array<InitialResponse>;
  initial: InitialResponse;
  getAggregateByStoreId: Array<AggregateResponse>;
  getCashCollections: CashCollectionSumResponse;
  getInformationCenters: Array<InformationCenterResponse>;
  getInformationCentersByStoreId: Array<InformationCenterResponse>;
  getInformationCenter: InformationCenterResponse;
  getInformationCentersByDevice: Array<InformationCenterResponse>;
  getDailyReports: Array<DailyReportResponse>;
};


export type QueryItemsByStoreIdArgs = {
  storeId?: Maybe<Scalars['String']>;
};


export type QueryItemArgs = {
  id: Scalars['String'];
};


export type QueryOrdersByStoreIdArgs = {
  onlyProgress: Scalars['Boolean'];
  orderDate: Scalars['DateTime'];
};


export type QueryOrderByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAggregateOrdersByStoreIdArgs = {
  onlyProgress: Scalars['Boolean'];
  orderDate: Scalars['DateTime'];
  storeId: Scalars['String'];
};


export type QueryLastsongArgs = {
  targetMonth: Scalars['DateTime'];
};


export type QueryCustomersByStoreIdArgs = {
  storeId?: Maybe<Scalars['String']>;
};


export type QueryCustomersByHostIdArgs = {
  hostId?: Maybe<Scalars['String']>;
};


export type QueryCustomerByIdArgs = {
  id: Scalars['String'];
};


export type QueryStoreArgs = {
  id: Scalars['String'];
};


export type QueryGetIsOrderItemLockedArgs = {
  storeId: Scalars['String'];
};


export type QueryGetOrderHistoriesByOrderIdArgs = {
  orderId: Scalars['String'];
};


export type QueryGetLatestOrderHistoryByOrderIdArgs = {
  orderId: Scalars['String'];
};


export type QueryHostsByStoreIdArgs = {
  storeId: Scalars['String'];
};


export type QueryHostsByTitleIdArgs = {
  titleId: Scalars['String'];
};


export type QueryHostArgs = {
  id: Scalars['String'];
};


export type QueryTitleArgs = {
  id: Scalars['String'];
};


export type QueryAccountReceivablesByCustomerIdArgs = {
  customerId: Scalars['String'];
};


export type QueryGetCustomerFilesByCustomerIdArgs = {
  customerId?: Maybe<Scalars['String']>;
};


export type QueryGetOrderItemHistoryArgs = {
  orderItemId: Scalars['String'];
  orderHistoryId: Scalars['String'];
};


export type QueryDevicesByStoreIdArgs = {
  storeId: Scalars['String'];
};


export type QueryDeviceArgs = {
  id: Scalars['String'];
};


export type QueryPenaltiesByStoreIdArgs = {
  storeId?: Maybe<Scalars['String']>;
};


export type QueryPenaltyArgs = {
  id: Scalars['String'];
};


export type QueryExpensesByStoreIdArgs = {
  targetMonth?: Maybe<Scalars['DateTime']>;
  storeId?: Maybe<Scalars['String']>;
};


export type QueryExpenseArgs = {
  id: Scalars['String'];
};


export type QueryExpenseItemArgs = {
  id: Scalars['String'];
};


export type QueryHostPenaltiesByStoreIdArgs = {
  targetMonth?: Maybe<Scalars['DateTime']>;
  storeId?: Maybe<Scalars['String']>;
};


export type QueryStoreTablesByStoreIdArgs = {
  storeId: Scalars['String'];
};


export type QueryStoreTableArgs = {
  id: Scalars['String'];
};


export type QueryAdminsByStoreIdArgs = {
  storeId: Scalars['String'];
};


export type QueryAdminByIdArgs = {
  id: Scalars['String'];
};


export type QueryInitialsByStoreIdArgs = {
  storeId: Scalars['String'];
};


export type QueryInitialArgs = {
  id: Scalars['String'];
};


export type QueryGetAggregateByStoreIdArgs = {
  startDate: Scalars['DateTime'];
  storeId: Scalars['String'];
};


export type QueryGetCashCollectionsArgs = {
  targetMonth: Scalars['DateTime'];
  storeId: Scalars['String'];
};


export type QueryGetInformationCentersByStoreIdArgs = {
  storeId: Scalars['String'];
};


export type QueryGetInformationCenterArgs = {
  id: Scalars['String'];
};


export type QueryGetDailyReportsArgs = {
  targetMonth: Scalars['DateTime'];
  storeId: Scalars['String'];
};

export type StoreResponse = {
  __typename?: 'StoreResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  receiptPrinterIpAddress?: Maybe<Scalars['String']>;
  serviceFee: Scalars['Int'];
  isOrderItemLocked?: Maybe<Scalars['Boolean']>;
  orderItemLockTime?: Maybe<Scalars['String']>;
  staffReservationFee?: Maybe<Scalars['Int']>;
};

export type StoreTableResponse = {
  __typename?: 'StoreTableResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
};

export type TentativeDeleteOrderInput = {
  id: Scalars['String'];
  isTentativeDelete: Scalars['Boolean'];
};

export type TitleResponse = {
  __typename?: 'TitleResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  sortNo: Scalars['Int'];
};

export type UpdateAdminInput = {
  account: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateCashCollectionInput = {
  eventDate: Scalars['DateTime'];
  itemName: Scalars['String'];
  amount: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  storeId: Scalars['String'];
};

export type UpdateCustomerInput = {
  name?: Maybe<Scalars['String']>;
};

export type UpdateDepositAccountReceivableInput = {
  cash: Scalars['Int'];
  creditCard: Scalars['Int'];
  deposit: Scalars['Int'];
  depositDate: Scalars['DateTime'];
};

export type UpdateDeviceInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  account: Scalars['String'];
  password?: Maybe<Scalars['String']>;
};

export type UpdateExpenseInput = {
  price: Scalars['Int'];
  eventDate: Scalars['DateTime'];
  type: ExpenseType;
  shopName?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  expenseItemId?: Maybe<Scalars['String']>;
};

export type UpdateExpenseItemInput = {
  name: Scalars['String'];
  examples?: Maybe<Scalars['String']>;
  sortNo: Scalars['Int'];
};

export type UpdateHostInput = {
  name: Scalars['String'];
  yomi?: Maybe<Scalars['String']>;
  status: HostStatus;
  hostIsNumber: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  titleId?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  sortNo: Scalars['Int'];
};

export type UpdateHostPenaltyInput = {
  penaltyDate: Scalars['DateTime'];
  hostId: Scalars['String'];
  penaltyId: Scalars['String'];
  price: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};

export type UpdateInformationCenterInput = {
  name: Scalars['String'];
  sortNo: Scalars['Int'];
};

export type UpdateInitialInput = {
  name: Scalars['String'];
  price: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};

export type UpdateIsKitchenCheckedInput = {
  orderItemId: Scalars['String'];
  isKitchenChecked: Scalars['Boolean'];
};

export type UpdateIsOrderItemLockDisabledInput = {
  deviceId: Scalars['String'];
  isOrderItemLockDisabled: Scalars['Boolean'];
};

export type UpdateIsOrderItemLockedInput = {
  storeId: Scalars['String'];
  isOrderItemLocked: Scalars['Boolean'];
};

export type UpdateIsUsedForLastsongInput = {
  orderItemId: Scalars['String'];
  isUsedForLastsong: Scalars['Boolean'];
};

export type UpdateItemInput = {
  name: Scalars['String'];
  category: ItemCategory;
  price: Scalars['Int'];
  sortNo: Scalars['Int'];
  bottleBack: Scalars['Int'];
  accessControl: Array<DeviceType>;
};

export type UpdateItemOrderInput = {
  itemId: Scalars['String'];
  quantity: Scalars['Int'];
  status: OrderItemStatus;
  pageIndex: Scalars['Int'];
};

export type UpdateItemOrderStatusInput = {
  status: OrderItemStatus;
};

export type UpdateOrderInput = {
  storeTableId?: Maybe<Scalars['String']>;
  initialCount?: Maybe<Scalars['Int']>;
  limitAmount?: Maybe<Scalars['Int']>;
  status?: Maybe<OrderStatus>;
};

export type UpdateOrderPaymentInput = {
  cash?: Maybe<Scalars['Int']>;
  creditCard?: Maybe<Scalars['Int']>;
  accountReceivable?: Maybe<Scalars['Int']>;
  tipping?: Maybe<Scalars['Int']>;
  optionalAmount?: Maybe<Scalars['Int']>;
  hasReceipt?: Maybe<Scalars['Boolean']>;
};

export type UpdatePenaltyInput = {
  name: Scalars['String'];
  price: Scalars['Int'];
};

export type UpdateStoreInput = {
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  receiptPrinterIpAddress?: Maybe<Scalars['String']>;
  serviceFee: Scalars['Int'];
  orderItemLockTime?: Maybe<Scalars['String']>;
  staffReservationFee?: Maybe<Scalars['Int']>;
};

export type UpdateStoreTableInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type UpdateTitleInput = {
  name: Scalars['String'];
  sortNo: Scalars['Int'];
};

export type AdminDataFragment = (
  { __typename?: 'AdminResponse' }
  & Pick<AdminResponse, 'id' | 'account' | 'storeId' | 'isMaster' | 'isCollector' | 'type'>
  & { store?: Maybe<(
    { __typename?: 'StoreResponse' }
    & StoreDataFragment
  )> }
);

export type GetAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminQuery = (
  { __typename?: 'Query' }
  & { admin: (
    { __typename?: 'AdminResponse' }
    & AdminDataFragment
  ) }
);

export type GetAdminByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAdminByIdQuery = (
  { __typename?: 'Query' }
  & { adminById: (
    { __typename?: 'AdminResponse' }
    & AdminDataFragment
  ) }
);

export type GetAdminsByStoreIdQueryVariables = Exact<{
  storeId: Scalars['String'];
}>;


export type GetAdminsByStoreIdQuery = (
  { __typename?: 'Query' }
  & { adminsByStoreId: Array<(
    { __typename?: 'AdminResponse' }
    & AdminDataFragment
  )> }
);

export type CreateAdminMutationVariables = Exact<{
  storeId: Scalars['String'];
  account: Scalars['String'];
  password: Scalars['String'];
  isCollector?: Maybe<Scalars['Boolean']>;
}>;


export type CreateAdminMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAdmin'>
);

export type UpdateAdminMutationVariables = Exact<{
  account: Scalars['String'];
  password: Scalars['String'];
}>;


export type UpdateAdminMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAdmin'>
);

export type DeleteAdminMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAdminMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAdmin'>
);

export type CustomerDataFragment = (
  { __typename?: 'CustomerResponse' }
  & Pick<CustomerResponse, 'id' | 'name' | 'hostName' | 'storeId' | 'hostId'>
);

export type GetCustomersByStoreIdQueryVariables = Exact<{
  storeId: Scalars['String'];
}>;


export type GetCustomersByStoreIdQuery = (
  { __typename?: 'Query' }
  & { customersByStoreId: Array<(
    { __typename?: 'CustomerResponse' }
    & Pick<CustomerResponse, 'accountReceivableSum'>
    & CustomerDataFragment
  )> }
);

export type GetCustomerByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCustomerByIdQuery = (
  { __typename?: 'Query' }
  & { customerById: (
    { __typename?: 'CustomerResponse' }
    & CustomerDataFragment
  ) }
);

export type UpdateCustomerMutationVariables = Exact<{
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCustomer'>
);

export type DeleteCustomerMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCustomer'>
);

export type MergeCustomersMutationVariables = Exact<{
  parentId: Scalars['String'];
  childId: Scalars['String'];
}>;


export type MergeCustomersMutation = (
  { __typename?: 'Mutation' }
  & { mergeCustomers: (
    { __typename?: 'CustomerResponse' }
    & CustomerDataFragment
  ) }
);

export type DeviceDataFragment = (
  { __typename?: 'DeviceResponse' }
  & Pick<DeviceResponse, 'id' | 'name' | 'description' | 'type' | 'account' | 'storeId'>
);

export type GetDevicesByStoreIdQueryVariables = Exact<{
  storeId: Scalars['String'];
}>;


export type GetDevicesByStoreIdQuery = (
  { __typename?: 'Query' }
  & { devicesByStoreId: Array<(
    { __typename?: 'DeviceResponse' }
    & DeviceDataFragment
  )> }
);

export type GetDeviceByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetDeviceByIdQuery = (
  { __typename?: 'Query' }
  & { device: (
    { __typename?: 'DeviceResponse' }
    & DeviceDataFragment
  ) }
);

export type CreateDeviceMutationVariables = Exact<{
  storeId: Scalars['String'];
  name: Scalars['String'];
  type: DeviceType;
  description?: Maybe<Scalars['String']>;
  account: Scalars['String'];
  password: Scalars['String'];
}>;


export type CreateDeviceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDevice'>
);

export type UpdateDeviceMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  account: Scalars['String'];
  password?: Maybe<Scalars['String']>;
}>;


export type UpdateDeviceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDevice'>
);

export type DeleteDeviceMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDeviceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDevice'>
);

export type ExpenseItemDataFragment = (
  { __typename?: 'ExpenseItemResponse' }
  & Pick<ExpenseItemResponse, 'id' | 'name' | 'examples' | 'sortNo'>
);

export type GetExpenseItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExpenseItemsQuery = (
  { __typename?: 'Query' }
  & { expenseItems: Array<(
    { __typename?: 'ExpenseItemResponse' }
    & ExpenseItemDataFragment
  )> }
);

export type GetExpenseItemQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetExpenseItemQuery = (
  { __typename?: 'Query' }
  & { expenseItem: (
    { __typename?: 'ExpenseItemResponse' }
    & ExpenseItemDataFragment
  ) }
);

export type CreateExpenseItemMutationVariables = Exact<{
  name: Scalars['String'];
  examples?: Maybe<Scalars['String']>;
  sortNo: Scalars['Int'];
}>;


export type CreateExpenseItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createExpenseItem'>
);

export type UpdateExpenseItemMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  examples?: Maybe<Scalars['String']>;
  sortNo: Scalars['Int'];
}>;


export type UpdateExpenseItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateExpenseItem'>
);

export type DeleteExpenseItemMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteExpenseItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteExpenseItem'>
);

export type HostDataFragment = (
  { __typename?: 'HostResponse' }
  & Pick<HostResponse, 'id' | 'name' | 'yomi' | 'hostIsNumber' | 'status' | 'startDate' | 'storeId' | 'sortNo'>
  & { title?: Maybe<(
    { __typename?: 'TitleResponse' }
    & TitleDataFragment
  )>, store?: Maybe<(
    { __typename?: 'StoreResponse' }
    & StoreDataFragment
  )> }
);

export type GetHostsByStoreIdQueryVariables = Exact<{
  storeId: Scalars['String'];
}>;


export type GetHostsByStoreIdQuery = (
  { __typename?: 'Query' }
  & { hostsByStoreId: Array<(
    { __typename?: 'HostResponse' }
    & HostDataFragment
  )> }
);

export type GetHostsByTitleIdQueryVariables = Exact<{
  titleId: Scalars['String'];
}>;


export type GetHostsByTitleIdQuery = (
  { __typename?: 'Query' }
  & { hostsByTitleId: Array<(
    { __typename?: 'HostResponse' }
    & Pick<HostResponse, 'sales' | 'appoints'>
    & { store?: Maybe<(
      { __typename?: 'StoreResponse' }
      & StoreDataFragment
    )> }
    & HostDataFragment
  )>, title: (
    { __typename?: 'TitleResponse' }
    & TitleDataFragment
  ) }
);

export type GetHostQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetHostQuery = (
  { __typename?: 'Query' }
  & { host: (
    { __typename?: 'HostResponse' }
    & HostDataFragment
  ) }
);

export type CreateHostMutationVariables = Exact<{
  storeId: Scalars['String'];
  name: Scalars['String'];
  yomi?: Maybe<Scalars['String']>;
  hostIsNumber: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTime']>;
  titleId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  sortNo: Scalars['Int'];
}>;


export type CreateHostMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createHost'>
);

export type UpdateHostMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  yomi?: Maybe<Scalars['String']>;
  status: HostStatus;
  hostIsNumber: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTime']>;
  titleId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  sortNo: Scalars['Int'];
}>;


export type UpdateHostMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateHost'>
);

export type DeleteHostMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteHostMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteHost'>
);

export type InformationCenterDataFragment = (
  { __typename?: 'InformationCenterResponse' }
  & Pick<InformationCenterResponse, 'id' | 'name' | 'sortNo' | 'storeId'>
);

export type GetInformationCentersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInformationCentersQuery = (
  { __typename?: 'Query' }
  & { getInformationCenters: Array<(
    { __typename?: 'InformationCenterResponse' }
    & InformationCenterDataFragment
  )> }
);

export type GetInformationCentersByStoreIdQueryVariables = Exact<{
  storeId: Scalars['String'];
}>;


export type GetInformationCentersByStoreIdQuery = (
  { __typename?: 'Query' }
  & { getInformationCentersByStoreId: Array<(
    { __typename?: 'InformationCenterResponse' }
    & InformationCenterDataFragment
  )> }
);

export type GetInformationCenterQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetInformationCenterQuery = (
  { __typename?: 'Query' }
  & { getInformationCenter: (
    { __typename?: 'InformationCenterResponse' }
    & InformationCenterDataFragment
  ) }
);

export type CreateInformationCenterMutationVariables = Exact<{
  storeId: Scalars['String'];
  name: Scalars['String'];
  sortNo: Scalars['Int'];
}>;


export type CreateInformationCenterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createInformationCenter'>
);

export type UpdateInformationCenterMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  sortNo: Scalars['Int'];
}>;


export type UpdateInformationCenterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateInformationCenter'>
);

export type DeleteInformationCenterMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteInformationCenterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteInformationCenter'>
);

export type InitialDataFragment = (
  { __typename?: 'InitialResponse' }
  & Pick<InitialResponse, 'id' | 'name' | 'price' | 'description' | 'storeId'>
);

export type GetInitialsByStoreIdQueryVariables = Exact<{
  storeId: Scalars['String'];
}>;


export type GetInitialsByStoreIdQuery = (
  { __typename?: 'Query' }
  & { initialsByStoreId: Array<(
    { __typename?: 'InitialResponse' }
    & InitialDataFragment
  )> }
);

export type GetInitialByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetInitialByIdQuery = (
  { __typename?: 'Query' }
  & { initial: (
    { __typename?: 'InitialResponse' }
    & InitialDataFragment
  ) }
);

export type CreateInitialMutationVariables = Exact<{
  storeId: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
}>;


export type CreateInitialMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createInitial'>
);

export type UpdateInitialMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateInitialMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateInitial'>
);

export type DeleteInitialMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteInitialMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteInitial'>
);

export type ItemDataFragment = (
  { __typename?: 'ItemResponse' }
  & Pick<ItemResponse, 'id' | 'name' | 'category' | 'price' | 'sortNo' | 'bottleBack' | 'accessControl' | 'storeId'>
);

export type GetItemsByStoreIdQueryVariables = Exact<{
  storeId: Scalars['String'];
}>;


export type GetItemsByStoreIdQuery = (
  { __typename?: 'Query' }
  & { itemsByStoreId: Array<(
    { __typename?: 'ItemResponse' }
    & { host?: Maybe<(
      { __typename?: 'HostResponse' }
      & HostDataFragment
    )> }
    & ItemDataFragment
  )> }
);

export type GetItemQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetItemQuery = (
  { __typename?: 'Query' }
  & { item: (
    { __typename?: 'ItemResponse' }
    & { host?: Maybe<(
      { __typename?: 'HostResponse' }
      & HostDataFragment
    )> }
    & ItemDataFragment
  ) }
);

export type CreateItemMutationVariables = Exact<{
  storeId: Scalars['String'];
  name: Scalars['String'];
  category: ItemCategory;
  price: Scalars['Int'];
  isFreeDrink: Scalars['Boolean'];
  sortNo: Scalars['Int'];
  bottleBack: Scalars['Int'];
  accessControl: Array<DeviceType> | DeviceType;
}>;


export type CreateItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createItem'>
);

export type DeleteItemMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteItem'>
);

export type UpdateItemMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  category: ItemCategory;
  price: Scalars['Int'];
  sortNo: Scalars['Int'];
  bottleBack: Scalars['Int'];
  accessControl: Array<DeviceType> | DeviceType;
}>;


export type UpdateItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateItem'>
);

export type BulkUpdateItemsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
  category?: Maybe<ItemCategory>;
  price?: Maybe<Scalars['Int']>;
  sortNo?: Maybe<Scalars['Int']>;
  bottleBack?: Maybe<Scalars['Int']>;
  accessControl?: Maybe<Array<DeviceType> | DeviceType>;
}>;


export type BulkUpdateItemsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bulkUpdateItems'>
);

export type AdminLoginMutationVariables = Exact<{
  account: Scalars['String'];
  password: Scalars['String'];
}>;


export type AdminLoginMutation = (
  { __typename?: 'Mutation' }
  & { adminLogin: (
    { __typename?: 'AdminLoginResponse' }
    & Pick<AdminLoginResponse, 'token' | 'isMaster' | 'isCollector' | 'adminName' | 'type'>
  ) }
);

export type PenaltyDataFragment = (
  { __typename?: 'PenaltyResponse' }
  & Pick<PenaltyResponse, 'id' | 'name' | 'price' | 'storeId'>
);

export type GetPenaltiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPenaltiesQuery = (
  { __typename?: 'Query' }
  & { penalties: Array<(
    { __typename?: 'PenaltyResponse' }
    & PenaltyDataFragment
  )> }
);

export type GetPenaltiesByStoreIdQueryVariables = Exact<{
  storeId: Scalars['String'];
}>;


export type GetPenaltiesByStoreIdQuery = (
  { __typename?: 'Query' }
  & { penaltiesByStoreId: Array<(
    { __typename?: 'PenaltyResponse' }
    & PenaltyDataFragment
  )> }
);

export type GetPenaltyQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPenaltyQuery = (
  { __typename?: 'Query' }
  & { penalty: (
    { __typename?: 'PenaltyResponse' }
    & PenaltyDataFragment
  ) }
);

export type CreatePenaltyMutationVariables = Exact<{
  storeId: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
}>;


export type CreatePenaltyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPenalty'>
);

export type UpdatePenaltyMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
}>;


export type UpdatePenaltyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePenalty'>
);

export type DeletePenaltyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePenaltyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePenalty'>
);

export type StoreTableDataFragment = (
  { __typename?: 'StoreTableResponse' }
  & Pick<StoreTableResponse, 'id' | 'name' | 'description' | 'storeId'>
);

export type GetStoreTablesByStoreIdQueryVariables = Exact<{
  storeId: Scalars['String'];
}>;


export type GetStoreTablesByStoreIdQuery = (
  { __typename?: 'Query' }
  & { storeTablesByStoreId: Array<(
    { __typename?: 'StoreTableResponse' }
    & StoreTableDataFragment
  )> }
);

export type GetStoreTableByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetStoreTableByIdQuery = (
  { __typename?: 'Query' }
  & { storeTable: (
    { __typename?: 'StoreTableResponse' }
    & StoreTableDataFragment
  ) }
);

export type CreateStoreTableMutationVariables = Exact<{
  storeId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type CreateStoreTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStoreTable'>
);

export type UpdateStoreTableMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateStoreTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStoreTable'>
);

export type DeleteStoreTableMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteStoreTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStoreTable'>
);

export type StoreDataFragment = (
  { __typename?: 'StoreResponse' }
  & Pick<StoreResponse, 'id' | 'name' | 'description' | 'receiptPrinterIpAddress' | 'serviceFee' | 'orderItemLockTime' | 'staffReservationFee'>
);

export type GetStoresQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStoresQuery = (
  { __typename?: 'Query' }
  & { stores: Array<(
    { __typename?: 'StoreResponse' }
    & StoreDataFragment
  )> }
);

export type GetStoreQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetStoreQuery = (
  { __typename?: 'Query' }
  & { store: (
    { __typename?: 'StoreResponse' }
    & Pick<StoreResponse, 'icon'>
    & StoreDataFragment
  ) }
);

export type CreateStoreMutationVariables = Exact<{
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  receiptPrinterIpAddress?: Maybe<Scalars['String']>;
  serviceFee: Scalars['Int'];
  orderItemLockTime?: Maybe<Scalars['String']>;
  staffReservationFee?: Maybe<Scalars['Int']>;
}>;


export type CreateStoreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStore'>
);

export type UpdateStoreMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  receiptPrinterIpAddress?: Maybe<Scalars['String']>;
  serviceFee: Scalars['Int'];
  orderItemLockTime?: Maybe<Scalars['String']>;
  staffReservationFee?: Maybe<Scalars['Int']>;
}>;


export type UpdateStoreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStore'>
);

export type DeleteStoreMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteStoreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStore'>
);

export type TitleDataFragment = (
  { __typename?: 'TitleResponse' }
  & Pick<TitleResponse, 'id' | 'name' | 'sortNo'>
);

export type GetTitlesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTitlesQuery = (
  { __typename?: 'Query' }
  & { titles: Array<(
    { __typename?: 'TitleResponse' }
    & TitleDataFragment
  )> }
);

export type GetTitleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTitleQuery = (
  { __typename?: 'Query' }
  & { title: (
    { __typename?: 'TitleResponse' }
    & TitleDataFragment
  ) }
);

export type CreateTitleMutationVariables = Exact<{
  name: Scalars['String'];
  sortNo: Scalars['Int'];
}>;


export type CreateTitleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createTitle'>
);

export type UpdateTitleMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  sortNo: Scalars['Int'];
}>;


export type UpdateTitleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateTitle'>
);

export type DeleteTitleMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteTitleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTitle'>
);

export const StoreDataFragmentDoc = gql`
    fragment storeData on StoreResponse {
  id
  name
  description
  receiptPrinterIpAddress
  serviceFee
  orderItemLockTime
  staffReservationFee
}
    `;
export const AdminDataFragmentDoc = gql`
    fragment adminData on AdminResponse {
  id
  account
  storeId
  isMaster
  isCollector
  type
  store {
    ...storeData
  }
}
    ${StoreDataFragmentDoc}`;
export const CustomerDataFragmentDoc = gql`
    fragment customerData on CustomerResponse {
  id
  name
  hostName
  storeId
  hostId
}
    `;
export const DeviceDataFragmentDoc = gql`
    fragment deviceData on DeviceResponse {
  id
  name
  description
  type
  account
  storeId
}
    `;
export const ExpenseItemDataFragmentDoc = gql`
    fragment expenseItemData on ExpenseItemResponse {
  id
  name
  examples
  sortNo
}
    `;
export const TitleDataFragmentDoc = gql`
    fragment titleData on TitleResponse {
  id
  name
  sortNo
}
    `;
export const HostDataFragmentDoc = gql`
    fragment hostData on HostResponse {
  id
  name
  yomi
  hostIsNumber
  status
  startDate
  storeId
  sortNo
  title {
    ...titleData
  }
  store {
    ...storeData
  }
}
    ${TitleDataFragmentDoc}
${StoreDataFragmentDoc}`;
export const InformationCenterDataFragmentDoc = gql`
    fragment informationCenterData on InformationCenterResponse {
  id
  name
  sortNo
  storeId
}
    `;
export const InitialDataFragmentDoc = gql`
    fragment initialData on InitialResponse {
  id
  name
  price
  description
  storeId
}
    `;
export const ItemDataFragmentDoc = gql`
    fragment itemData on ItemResponse {
  id
  name
  category
  price
  sortNo
  bottleBack
  accessControl
  storeId
}
    `;
export const PenaltyDataFragmentDoc = gql`
    fragment penaltyData on PenaltyResponse {
  id
  name
  price
  storeId
}
    `;
export const StoreTableDataFragmentDoc = gql`
    fragment storeTableData on StoreTableResponse {
  id
  name
  description
  storeId
}
    `;
export const GetAdminDocument = gql`
    query getAdmin {
  admin {
    ...adminData
  }
}
    ${AdminDataFragmentDoc}`;

/**
 * __useGetAdminQuery__
 *
 * To run a query within a React component, call `useGetAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminQuery, GetAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminQuery, GetAdminQueryVariables>(GetAdminDocument, options);
      }
export function useGetAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminQuery, GetAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminQuery, GetAdminQueryVariables>(GetAdminDocument, options);
        }
export type GetAdminQueryHookResult = ReturnType<typeof useGetAdminQuery>;
export type GetAdminLazyQueryHookResult = ReturnType<typeof useGetAdminLazyQuery>;
export type GetAdminQueryResult = Apollo.QueryResult<GetAdminQuery, GetAdminQueryVariables>;
export const GetAdminByIdDocument = gql`
    query getAdminById($id: String!) {
  adminById(id: $id) {
    ...adminData
  }
}
    ${AdminDataFragmentDoc}`;

/**
 * __useGetAdminByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAdminByIdQuery, GetAdminByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminByIdQuery, GetAdminByIdQueryVariables>(GetAdminByIdDocument, options);
      }
export function useGetAdminByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminByIdQuery, GetAdminByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminByIdQuery, GetAdminByIdQueryVariables>(GetAdminByIdDocument, options);
        }
export type GetAdminByIdQueryHookResult = ReturnType<typeof useGetAdminByIdQuery>;
export type GetAdminByIdLazyQueryHookResult = ReturnType<typeof useGetAdminByIdLazyQuery>;
export type GetAdminByIdQueryResult = Apollo.QueryResult<GetAdminByIdQuery, GetAdminByIdQueryVariables>;
export const GetAdminsByStoreIdDocument = gql`
    query getAdminsByStoreId($storeId: String!) {
  adminsByStoreId(storeId: $storeId) {
    ...adminData
  }
}
    ${AdminDataFragmentDoc}`;

/**
 * __useGetAdminsByStoreIdQuery__
 *
 * To run a query within a React component, call `useGetAdminsByStoreIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminsByStoreIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminsByStoreIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetAdminsByStoreIdQuery(baseOptions: Apollo.QueryHookOptions<GetAdminsByStoreIdQuery, GetAdminsByStoreIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminsByStoreIdQuery, GetAdminsByStoreIdQueryVariables>(GetAdminsByStoreIdDocument, options);
      }
export function useGetAdminsByStoreIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminsByStoreIdQuery, GetAdminsByStoreIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminsByStoreIdQuery, GetAdminsByStoreIdQueryVariables>(GetAdminsByStoreIdDocument, options);
        }
export type GetAdminsByStoreIdQueryHookResult = ReturnType<typeof useGetAdminsByStoreIdQuery>;
export type GetAdminsByStoreIdLazyQueryHookResult = ReturnType<typeof useGetAdminsByStoreIdLazyQuery>;
export type GetAdminsByStoreIdQueryResult = Apollo.QueryResult<GetAdminsByStoreIdQuery, GetAdminsByStoreIdQueryVariables>;
export const CreateAdminDocument = gql`
    mutation createAdmin($storeId: String!, $account: String!, $password: String!, $isCollector: Boolean) {
  createAdmin(
    input: {storeId: $storeId, account: $account, password: $password, isCollector: $isCollector}
  )
}
    `;
export type CreateAdminMutationFn = Apollo.MutationFunction<CreateAdminMutation, CreateAdminMutationVariables>;

/**
 * __useCreateAdminMutation__
 *
 * To run a mutation, you first call `useCreateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminMutation, { data, loading, error }] = useCreateAdminMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      account: // value for 'account'
 *      password: // value for 'password'
 *      isCollector: // value for 'isCollector'
 *   },
 * });
 */
export function useCreateAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminMutation, CreateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminMutation, CreateAdminMutationVariables>(CreateAdminDocument, options);
      }
export type CreateAdminMutationHookResult = ReturnType<typeof useCreateAdminMutation>;
export type CreateAdminMutationResult = Apollo.MutationResult<CreateAdminMutation>;
export type CreateAdminMutationOptions = Apollo.BaseMutationOptions<CreateAdminMutation, CreateAdminMutationVariables>;
export const UpdateAdminDocument = gql`
    mutation updateAdmin($account: String!, $password: String!) {
  updateAdmin(input: {account: $account, password: $password})
}
    `;
export type UpdateAdminMutationFn = Apollo.MutationFunction<UpdateAdminMutation, UpdateAdminMutationVariables>;

/**
 * __useUpdateAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminMutation, { data, loading, error }] = useUpdateAdminMutation({
 *   variables: {
 *      account: // value for 'account'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminMutation, UpdateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminMutation, UpdateAdminMutationVariables>(UpdateAdminDocument, options);
      }
export type UpdateAdminMutationHookResult = ReturnType<typeof useUpdateAdminMutation>;
export type UpdateAdminMutationResult = Apollo.MutationResult<UpdateAdminMutation>;
export type UpdateAdminMutationOptions = Apollo.BaseMutationOptions<UpdateAdminMutation, UpdateAdminMutationVariables>;
export const DeleteAdminDocument = gql`
    mutation deleteAdmin($id: String!) {
  deleteAdmin(id: $id)
}
    `;
export type DeleteAdminMutationFn = Apollo.MutationFunction<DeleteAdminMutation, DeleteAdminMutationVariables>;

/**
 * __useDeleteAdminMutation__
 *
 * To run a mutation, you first call `useDeleteAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminMutation, { data, loading, error }] = useDeleteAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdminMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdminMutation, DeleteAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdminMutation, DeleteAdminMutationVariables>(DeleteAdminDocument, options);
      }
export type DeleteAdminMutationHookResult = ReturnType<typeof useDeleteAdminMutation>;
export type DeleteAdminMutationResult = Apollo.MutationResult<DeleteAdminMutation>;
export type DeleteAdminMutationOptions = Apollo.BaseMutationOptions<DeleteAdminMutation, DeleteAdminMutationVariables>;
export const GetCustomersByStoreIdDocument = gql`
    query getCustomersByStoreId($storeId: String!) {
  customersByStoreId(storeId: $storeId) {
    ...customerData
    accountReceivableSum
  }
}
    ${CustomerDataFragmentDoc}`;

/**
 * __useGetCustomersByStoreIdQuery__
 *
 * To run a query within a React component, call `useGetCustomersByStoreIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersByStoreIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersByStoreIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetCustomersByStoreIdQuery(baseOptions: Apollo.QueryHookOptions<GetCustomersByStoreIdQuery, GetCustomersByStoreIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersByStoreIdQuery, GetCustomersByStoreIdQueryVariables>(GetCustomersByStoreIdDocument, options);
      }
export function useGetCustomersByStoreIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersByStoreIdQuery, GetCustomersByStoreIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersByStoreIdQuery, GetCustomersByStoreIdQueryVariables>(GetCustomersByStoreIdDocument, options);
        }
export type GetCustomersByStoreIdQueryHookResult = ReturnType<typeof useGetCustomersByStoreIdQuery>;
export type GetCustomersByStoreIdLazyQueryHookResult = ReturnType<typeof useGetCustomersByStoreIdLazyQuery>;
export type GetCustomersByStoreIdQueryResult = Apollo.QueryResult<GetCustomersByStoreIdQuery, GetCustomersByStoreIdQueryVariables>;
export const GetCustomerByIdDocument = gql`
    query getCustomerById($id: String!) {
  customerById(id: $id) {
    ...customerData
  }
}
    ${CustomerDataFragmentDoc}`;

/**
 * __useGetCustomerByIdQuery__
 *
 * To run a query within a React component, call `useGetCustomerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>(GetCustomerByIdDocument, options);
      }
export function useGetCustomerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>(GetCustomerByIdDocument, options);
        }
export type GetCustomerByIdQueryHookResult = ReturnType<typeof useGetCustomerByIdQuery>;
export type GetCustomerByIdLazyQueryHookResult = ReturnType<typeof useGetCustomerByIdLazyQuery>;
export type GetCustomerByIdQueryResult = Apollo.QueryResult<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($id: String!, $name: String) {
  updateCustomer(id: $id, input: {name: $name})
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const DeleteCustomerDocument = gql`
    mutation deleteCustomer($id: String!) {
  deleteCustomer(id: $id)
}
    `;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, options);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const MergeCustomersDocument = gql`
    mutation mergeCustomers($parentId: String!, $childId: String!) {
  mergeCustomers(parentId: $parentId, childId: $childId) {
    ...customerData
  }
}
    ${CustomerDataFragmentDoc}`;
export type MergeCustomersMutationFn = Apollo.MutationFunction<MergeCustomersMutation, MergeCustomersMutationVariables>;

/**
 * __useMergeCustomersMutation__
 *
 * To run a mutation, you first call `useMergeCustomersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeCustomersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeCustomersMutation, { data, loading, error }] = useMergeCustomersMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useMergeCustomersMutation(baseOptions?: Apollo.MutationHookOptions<MergeCustomersMutation, MergeCustomersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergeCustomersMutation, MergeCustomersMutationVariables>(MergeCustomersDocument, options);
      }
export type MergeCustomersMutationHookResult = ReturnType<typeof useMergeCustomersMutation>;
export type MergeCustomersMutationResult = Apollo.MutationResult<MergeCustomersMutation>;
export type MergeCustomersMutationOptions = Apollo.BaseMutationOptions<MergeCustomersMutation, MergeCustomersMutationVariables>;
export const GetDevicesByStoreIdDocument = gql`
    query getDevicesByStoreId($storeId: String!) {
  devicesByStoreId(storeId: $storeId) {
    ...deviceData
  }
}
    ${DeviceDataFragmentDoc}`;

/**
 * __useGetDevicesByStoreIdQuery__
 *
 * To run a query within a React component, call `useGetDevicesByStoreIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesByStoreIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesByStoreIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetDevicesByStoreIdQuery(baseOptions: Apollo.QueryHookOptions<GetDevicesByStoreIdQuery, GetDevicesByStoreIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDevicesByStoreIdQuery, GetDevicesByStoreIdQueryVariables>(GetDevicesByStoreIdDocument, options);
      }
export function useGetDevicesByStoreIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDevicesByStoreIdQuery, GetDevicesByStoreIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDevicesByStoreIdQuery, GetDevicesByStoreIdQueryVariables>(GetDevicesByStoreIdDocument, options);
        }
export type GetDevicesByStoreIdQueryHookResult = ReturnType<typeof useGetDevicesByStoreIdQuery>;
export type GetDevicesByStoreIdLazyQueryHookResult = ReturnType<typeof useGetDevicesByStoreIdLazyQuery>;
export type GetDevicesByStoreIdQueryResult = Apollo.QueryResult<GetDevicesByStoreIdQuery, GetDevicesByStoreIdQueryVariables>;
export const GetDeviceByIdDocument = gql`
    query getDeviceById($id: String!) {
  device(id: $id) {
    ...deviceData
  }
}
    ${DeviceDataFragmentDoc}`;

/**
 * __useGetDeviceByIdQuery__
 *
 * To run a query within a React component, call `useGetDeviceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeviceByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceByIdQuery, GetDeviceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceByIdQuery, GetDeviceByIdQueryVariables>(GetDeviceByIdDocument, options);
      }
export function useGetDeviceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceByIdQuery, GetDeviceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceByIdQuery, GetDeviceByIdQueryVariables>(GetDeviceByIdDocument, options);
        }
export type GetDeviceByIdQueryHookResult = ReturnType<typeof useGetDeviceByIdQuery>;
export type GetDeviceByIdLazyQueryHookResult = ReturnType<typeof useGetDeviceByIdLazyQuery>;
export type GetDeviceByIdQueryResult = Apollo.QueryResult<GetDeviceByIdQuery, GetDeviceByIdQueryVariables>;
export const CreateDeviceDocument = gql`
    mutation createDevice($storeId: String!, $name: String!, $type: DeviceType!, $description: String, $account: String!, $password: String!) {
  createDevice(
    input: {storeId: $storeId, name: $name, type: $type, description: $description, account: $account, password: $password}
  )
}
    `;
export type CreateDeviceMutationFn = Apollo.MutationFunction<CreateDeviceMutation, CreateDeviceMutationVariables>;

/**
 * __useCreateDeviceMutation__
 *
 * To run a mutation, you first call `useCreateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceMutation, { data, loading, error }] = useCreateDeviceMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      description: // value for 'description'
 *      account: // value for 'account'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeviceMutation, CreateDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeviceMutation, CreateDeviceMutationVariables>(CreateDeviceDocument, options);
      }
export type CreateDeviceMutationHookResult = ReturnType<typeof useCreateDeviceMutation>;
export type CreateDeviceMutationResult = Apollo.MutationResult<CreateDeviceMutation>;
export type CreateDeviceMutationOptions = Apollo.BaseMutationOptions<CreateDeviceMutation, CreateDeviceMutationVariables>;
export const UpdateDeviceDocument = gql`
    mutation updateDevice($id: String!, $name: String!, $description: String, $account: String!, $password: String) {
  updateDevice(
    id: $id
    input: {name: $name, description: $description, account: $account, password: $password}
  )
}
    `;
export type UpdateDeviceMutationFn = Apollo.MutationFunction<UpdateDeviceMutation, UpdateDeviceMutationVariables>;

/**
 * __useUpdateDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceMutation, { data, loading, error }] = useUpdateDeviceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      account: // value for 'account'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceMutation, UpdateDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceMutation, UpdateDeviceMutationVariables>(UpdateDeviceDocument, options);
      }
export type UpdateDeviceMutationHookResult = ReturnType<typeof useUpdateDeviceMutation>;
export type UpdateDeviceMutationResult = Apollo.MutationResult<UpdateDeviceMutation>;
export type UpdateDeviceMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceMutation, UpdateDeviceMutationVariables>;
export const DeleteDeviceDocument = gql`
    mutation deleteDevice($id: String!) {
  deleteDevice(id: $id)
}
    `;
export type DeleteDeviceMutationFn = Apollo.MutationFunction<DeleteDeviceMutation, DeleteDeviceMutationVariables>;

/**
 * __useDeleteDeviceMutation__
 *
 * To run a mutation, you first call `useDeleteDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeviceMutation, { data, loading, error }] = useDeleteDeviceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDeviceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeviceMutation, DeleteDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeviceMutation, DeleteDeviceMutationVariables>(DeleteDeviceDocument, options);
      }
export type DeleteDeviceMutationHookResult = ReturnType<typeof useDeleteDeviceMutation>;
export type DeleteDeviceMutationResult = Apollo.MutationResult<DeleteDeviceMutation>;
export type DeleteDeviceMutationOptions = Apollo.BaseMutationOptions<DeleteDeviceMutation, DeleteDeviceMutationVariables>;
export const GetExpenseItemsDocument = gql`
    query getExpenseItems {
  expenseItems {
    ...expenseItemData
  }
}
    ${ExpenseItemDataFragmentDoc}`;

/**
 * __useGetExpenseItemsQuery__
 *
 * To run a query within a React component, call `useGetExpenseItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExpenseItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetExpenseItemsQuery, GetExpenseItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExpenseItemsQuery, GetExpenseItemsQueryVariables>(GetExpenseItemsDocument, options);
      }
export function useGetExpenseItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExpenseItemsQuery, GetExpenseItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExpenseItemsQuery, GetExpenseItemsQueryVariables>(GetExpenseItemsDocument, options);
        }
export type GetExpenseItemsQueryHookResult = ReturnType<typeof useGetExpenseItemsQuery>;
export type GetExpenseItemsLazyQueryHookResult = ReturnType<typeof useGetExpenseItemsLazyQuery>;
export type GetExpenseItemsQueryResult = Apollo.QueryResult<GetExpenseItemsQuery, GetExpenseItemsQueryVariables>;
export const GetExpenseItemDocument = gql`
    query getExpenseItem($id: String!) {
  expenseItem(id: $id) {
    ...expenseItemData
  }
}
    ${ExpenseItemDataFragmentDoc}`;

/**
 * __useGetExpenseItemQuery__
 *
 * To run a query within a React component, call `useGetExpenseItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExpenseItemQuery(baseOptions: Apollo.QueryHookOptions<GetExpenseItemQuery, GetExpenseItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExpenseItemQuery, GetExpenseItemQueryVariables>(GetExpenseItemDocument, options);
      }
export function useGetExpenseItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExpenseItemQuery, GetExpenseItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExpenseItemQuery, GetExpenseItemQueryVariables>(GetExpenseItemDocument, options);
        }
export type GetExpenseItemQueryHookResult = ReturnType<typeof useGetExpenseItemQuery>;
export type GetExpenseItemLazyQueryHookResult = ReturnType<typeof useGetExpenseItemLazyQuery>;
export type GetExpenseItemQueryResult = Apollo.QueryResult<GetExpenseItemQuery, GetExpenseItemQueryVariables>;
export const CreateExpenseItemDocument = gql`
    mutation createExpenseItem($name: String!, $examples: String, $sortNo: Int!) {
  createExpenseItem(input: {name: $name, examples: $examples, sortNo: $sortNo})
}
    `;
export type CreateExpenseItemMutationFn = Apollo.MutationFunction<CreateExpenseItemMutation, CreateExpenseItemMutationVariables>;

/**
 * __useCreateExpenseItemMutation__
 *
 * To run a mutation, you first call `useCreateExpenseItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpenseItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpenseItemMutation, { data, loading, error }] = useCreateExpenseItemMutation({
 *   variables: {
 *      name: // value for 'name'
 *      examples: // value for 'examples'
 *      sortNo: // value for 'sortNo'
 *   },
 * });
 */
export function useCreateExpenseItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateExpenseItemMutation, CreateExpenseItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExpenseItemMutation, CreateExpenseItemMutationVariables>(CreateExpenseItemDocument, options);
      }
export type CreateExpenseItemMutationHookResult = ReturnType<typeof useCreateExpenseItemMutation>;
export type CreateExpenseItemMutationResult = Apollo.MutationResult<CreateExpenseItemMutation>;
export type CreateExpenseItemMutationOptions = Apollo.BaseMutationOptions<CreateExpenseItemMutation, CreateExpenseItemMutationVariables>;
export const UpdateExpenseItemDocument = gql`
    mutation updateExpenseItem($id: String!, $name: String!, $examples: String, $sortNo: Int!) {
  updateExpenseItem(
    id: $id
    input: {name: $name, examples: $examples, sortNo: $sortNo}
  )
}
    `;
export type UpdateExpenseItemMutationFn = Apollo.MutationFunction<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables>;

/**
 * __useUpdateExpenseItemMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseItemMutation, { data, loading, error }] = useUpdateExpenseItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      examples: // value for 'examples'
 *      sortNo: // value for 'sortNo'
 *   },
 * });
 */
export function useUpdateExpenseItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables>(UpdateExpenseItemDocument, options);
      }
export type UpdateExpenseItemMutationHookResult = ReturnType<typeof useUpdateExpenseItemMutation>;
export type UpdateExpenseItemMutationResult = Apollo.MutationResult<UpdateExpenseItemMutation>;
export type UpdateExpenseItemMutationOptions = Apollo.BaseMutationOptions<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables>;
export const DeleteExpenseItemDocument = gql`
    mutation deleteExpenseItem($id: String!) {
  deleteExpenseItem(id: $id)
}
    `;
export type DeleteExpenseItemMutationFn = Apollo.MutationFunction<DeleteExpenseItemMutation, DeleteExpenseItemMutationVariables>;

/**
 * __useDeleteExpenseItemMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseItemMutation, { data, loading, error }] = useDeleteExpenseItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExpenseItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteExpenseItemMutation, DeleteExpenseItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteExpenseItemMutation, DeleteExpenseItemMutationVariables>(DeleteExpenseItemDocument, options);
      }
export type DeleteExpenseItemMutationHookResult = ReturnType<typeof useDeleteExpenseItemMutation>;
export type DeleteExpenseItemMutationResult = Apollo.MutationResult<DeleteExpenseItemMutation>;
export type DeleteExpenseItemMutationOptions = Apollo.BaseMutationOptions<DeleteExpenseItemMutation, DeleteExpenseItemMutationVariables>;
export const GetHostsByStoreIdDocument = gql`
    query getHostsByStoreId($storeId: String!) {
  hostsByStoreId(storeId: $storeId) {
    ...hostData
  }
}
    ${HostDataFragmentDoc}`;

/**
 * __useGetHostsByStoreIdQuery__
 *
 * To run a query within a React component, call `useGetHostsByStoreIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHostsByStoreIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHostsByStoreIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetHostsByStoreIdQuery(baseOptions: Apollo.QueryHookOptions<GetHostsByStoreIdQuery, GetHostsByStoreIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHostsByStoreIdQuery, GetHostsByStoreIdQueryVariables>(GetHostsByStoreIdDocument, options);
      }
export function useGetHostsByStoreIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHostsByStoreIdQuery, GetHostsByStoreIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHostsByStoreIdQuery, GetHostsByStoreIdQueryVariables>(GetHostsByStoreIdDocument, options);
        }
export type GetHostsByStoreIdQueryHookResult = ReturnType<typeof useGetHostsByStoreIdQuery>;
export type GetHostsByStoreIdLazyQueryHookResult = ReturnType<typeof useGetHostsByStoreIdLazyQuery>;
export type GetHostsByStoreIdQueryResult = Apollo.QueryResult<GetHostsByStoreIdQuery, GetHostsByStoreIdQueryVariables>;
export const GetHostsByTitleIdDocument = gql`
    query getHostsByTitleId($titleId: String!) {
  hostsByTitleId(titleId: $titleId) {
    ...hostData
    sales
    appoints
    store {
      ...storeData
    }
  }
  title(id: $titleId) {
    ...titleData
  }
}
    ${HostDataFragmentDoc}
${StoreDataFragmentDoc}
${TitleDataFragmentDoc}`;

/**
 * __useGetHostsByTitleIdQuery__
 *
 * To run a query within a React component, call `useGetHostsByTitleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHostsByTitleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHostsByTitleIdQuery({
 *   variables: {
 *      titleId: // value for 'titleId'
 *   },
 * });
 */
export function useGetHostsByTitleIdQuery(baseOptions: Apollo.QueryHookOptions<GetHostsByTitleIdQuery, GetHostsByTitleIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHostsByTitleIdQuery, GetHostsByTitleIdQueryVariables>(GetHostsByTitleIdDocument, options);
      }
export function useGetHostsByTitleIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHostsByTitleIdQuery, GetHostsByTitleIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHostsByTitleIdQuery, GetHostsByTitleIdQueryVariables>(GetHostsByTitleIdDocument, options);
        }
export type GetHostsByTitleIdQueryHookResult = ReturnType<typeof useGetHostsByTitleIdQuery>;
export type GetHostsByTitleIdLazyQueryHookResult = ReturnType<typeof useGetHostsByTitleIdLazyQuery>;
export type GetHostsByTitleIdQueryResult = Apollo.QueryResult<GetHostsByTitleIdQuery, GetHostsByTitleIdQueryVariables>;
export const GetHostDocument = gql`
    query getHost($id: String!) {
  host(id: $id) {
    ...hostData
  }
}
    ${HostDataFragmentDoc}`;

/**
 * __useGetHostQuery__
 *
 * To run a query within a React component, call `useGetHostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHostQuery(baseOptions: Apollo.QueryHookOptions<GetHostQuery, GetHostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHostQuery, GetHostQueryVariables>(GetHostDocument, options);
      }
export function useGetHostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHostQuery, GetHostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHostQuery, GetHostQueryVariables>(GetHostDocument, options);
        }
export type GetHostQueryHookResult = ReturnType<typeof useGetHostQuery>;
export type GetHostLazyQueryHookResult = ReturnType<typeof useGetHostLazyQuery>;
export type GetHostQueryResult = Apollo.QueryResult<GetHostQuery, GetHostQueryVariables>;
export const CreateHostDocument = gql`
    mutation createHost($storeId: String!, $name: String!, $yomi: String, $hostIsNumber: Boolean!, $startDate: DateTime, $titleId: String, $title: String, $sortNo: Int!) {
  createHost(
    input: {storeId: $storeId, name: $name, yomi: $yomi, hostIsNumber: $hostIsNumber, startDate: $startDate, titleId: $titleId, title: $title, sortNo: $sortNo}
  )
}
    `;
export type CreateHostMutationFn = Apollo.MutationFunction<CreateHostMutation, CreateHostMutationVariables>;

/**
 * __useCreateHostMutation__
 *
 * To run a mutation, you first call `useCreateHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHostMutation, { data, loading, error }] = useCreateHostMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      name: // value for 'name'
 *      yomi: // value for 'yomi'
 *      hostIsNumber: // value for 'hostIsNumber'
 *      startDate: // value for 'startDate'
 *      titleId: // value for 'titleId'
 *      title: // value for 'title'
 *      sortNo: // value for 'sortNo'
 *   },
 * });
 */
export function useCreateHostMutation(baseOptions?: Apollo.MutationHookOptions<CreateHostMutation, CreateHostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHostMutation, CreateHostMutationVariables>(CreateHostDocument, options);
      }
export type CreateHostMutationHookResult = ReturnType<typeof useCreateHostMutation>;
export type CreateHostMutationResult = Apollo.MutationResult<CreateHostMutation>;
export type CreateHostMutationOptions = Apollo.BaseMutationOptions<CreateHostMutation, CreateHostMutationVariables>;
export const UpdateHostDocument = gql`
    mutation updateHost($id: String!, $name: String!, $yomi: String, $status: HostStatus!, $hostIsNumber: Boolean!, $startDate: DateTime, $titleId: String, $title: String, $storeId: String, $sortNo: Int!) {
  updateHost(
    id: $id
    input: {name: $name, yomi: $yomi, status: $status, hostIsNumber: $hostIsNumber, startDate: $startDate, titleId: $titleId, title: $title, storeId: $storeId, sortNo: $sortNo}
  )
}
    `;
export type UpdateHostMutationFn = Apollo.MutationFunction<UpdateHostMutation, UpdateHostMutationVariables>;

/**
 * __useUpdateHostMutation__
 *
 * To run a mutation, you first call `useUpdateHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHostMutation, { data, loading, error }] = useUpdateHostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      yomi: // value for 'yomi'
 *      status: // value for 'status'
 *      hostIsNumber: // value for 'hostIsNumber'
 *      startDate: // value for 'startDate'
 *      titleId: // value for 'titleId'
 *      title: // value for 'title'
 *      storeId: // value for 'storeId'
 *      sortNo: // value for 'sortNo'
 *   },
 * });
 */
export function useUpdateHostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHostMutation, UpdateHostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHostMutation, UpdateHostMutationVariables>(UpdateHostDocument, options);
      }
export type UpdateHostMutationHookResult = ReturnType<typeof useUpdateHostMutation>;
export type UpdateHostMutationResult = Apollo.MutationResult<UpdateHostMutation>;
export type UpdateHostMutationOptions = Apollo.BaseMutationOptions<UpdateHostMutation, UpdateHostMutationVariables>;
export const DeleteHostDocument = gql`
    mutation deleteHost($id: String!) {
  deleteHost(id: $id)
}
    `;
export type DeleteHostMutationFn = Apollo.MutationFunction<DeleteHostMutation, DeleteHostMutationVariables>;

/**
 * __useDeleteHostMutation__
 *
 * To run a mutation, you first call `useDeleteHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHostMutation, { data, loading, error }] = useDeleteHostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHostMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHostMutation, DeleteHostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHostMutation, DeleteHostMutationVariables>(DeleteHostDocument, options);
      }
export type DeleteHostMutationHookResult = ReturnType<typeof useDeleteHostMutation>;
export type DeleteHostMutationResult = Apollo.MutationResult<DeleteHostMutation>;
export type DeleteHostMutationOptions = Apollo.BaseMutationOptions<DeleteHostMutation, DeleteHostMutationVariables>;
export const GetInformationCentersDocument = gql`
    query getInformationCenters {
  getInformationCenters {
    ...informationCenterData
  }
}
    ${InformationCenterDataFragmentDoc}`;

/**
 * __useGetInformationCentersQuery__
 *
 * To run a query within a React component, call `useGetInformationCentersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInformationCentersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInformationCentersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInformationCentersQuery(baseOptions?: Apollo.QueryHookOptions<GetInformationCentersQuery, GetInformationCentersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInformationCentersQuery, GetInformationCentersQueryVariables>(GetInformationCentersDocument, options);
      }
export function useGetInformationCentersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInformationCentersQuery, GetInformationCentersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInformationCentersQuery, GetInformationCentersQueryVariables>(GetInformationCentersDocument, options);
        }
export type GetInformationCentersQueryHookResult = ReturnType<typeof useGetInformationCentersQuery>;
export type GetInformationCentersLazyQueryHookResult = ReturnType<typeof useGetInformationCentersLazyQuery>;
export type GetInformationCentersQueryResult = Apollo.QueryResult<GetInformationCentersQuery, GetInformationCentersQueryVariables>;
export const GetInformationCentersByStoreIdDocument = gql`
    query getInformationCentersByStoreId($storeId: String!) {
  getInformationCentersByStoreId(storeId: $storeId) {
    ...informationCenterData
  }
}
    ${InformationCenterDataFragmentDoc}`;

/**
 * __useGetInformationCentersByStoreIdQuery__
 *
 * To run a query within a React component, call `useGetInformationCentersByStoreIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInformationCentersByStoreIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInformationCentersByStoreIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetInformationCentersByStoreIdQuery(baseOptions: Apollo.QueryHookOptions<GetInformationCentersByStoreIdQuery, GetInformationCentersByStoreIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInformationCentersByStoreIdQuery, GetInformationCentersByStoreIdQueryVariables>(GetInformationCentersByStoreIdDocument, options);
      }
export function useGetInformationCentersByStoreIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInformationCentersByStoreIdQuery, GetInformationCentersByStoreIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInformationCentersByStoreIdQuery, GetInformationCentersByStoreIdQueryVariables>(GetInformationCentersByStoreIdDocument, options);
        }
export type GetInformationCentersByStoreIdQueryHookResult = ReturnType<typeof useGetInformationCentersByStoreIdQuery>;
export type GetInformationCentersByStoreIdLazyQueryHookResult = ReturnType<typeof useGetInformationCentersByStoreIdLazyQuery>;
export type GetInformationCentersByStoreIdQueryResult = Apollo.QueryResult<GetInformationCentersByStoreIdQuery, GetInformationCentersByStoreIdQueryVariables>;
export const GetInformationCenterDocument = gql`
    query getInformationCenter($id: String!) {
  getInformationCenter(id: $id) {
    ...informationCenterData
  }
}
    ${InformationCenterDataFragmentDoc}`;

/**
 * __useGetInformationCenterQuery__
 *
 * To run a query within a React component, call `useGetInformationCenterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInformationCenterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInformationCenterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInformationCenterQuery(baseOptions: Apollo.QueryHookOptions<GetInformationCenterQuery, GetInformationCenterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInformationCenterQuery, GetInformationCenterQueryVariables>(GetInformationCenterDocument, options);
      }
export function useGetInformationCenterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInformationCenterQuery, GetInformationCenterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInformationCenterQuery, GetInformationCenterQueryVariables>(GetInformationCenterDocument, options);
        }
export type GetInformationCenterQueryHookResult = ReturnType<typeof useGetInformationCenterQuery>;
export type GetInformationCenterLazyQueryHookResult = ReturnType<typeof useGetInformationCenterLazyQuery>;
export type GetInformationCenterQueryResult = Apollo.QueryResult<GetInformationCenterQuery, GetInformationCenterQueryVariables>;
export const CreateInformationCenterDocument = gql`
    mutation createInformationCenter($storeId: String!, $name: String!, $sortNo: Int!) {
  createInformationCenter(
    input: {storeId: $storeId, name: $name, sortNo: $sortNo}
  )
}
    `;
export type CreateInformationCenterMutationFn = Apollo.MutationFunction<CreateInformationCenterMutation, CreateInformationCenterMutationVariables>;

/**
 * __useCreateInformationCenterMutation__
 *
 * To run a mutation, you first call `useCreateInformationCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInformationCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInformationCenterMutation, { data, loading, error }] = useCreateInformationCenterMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      name: // value for 'name'
 *      sortNo: // value for 'sortNo'
 *   },
 * });
 */
export function useCreateInformationCenterMutation(baseOptions?: Apollo.MutationHookOptions<CreateInformationCenterMutation, CreateInformationCenterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInformationCenterMutation, CreateInformationCenterMutationVariables>(CreateInformationCenterDocument, options);
      }
export type CreateInformationCenterMutationHookResult = ReturnType<typeof useCreateInformationCenterMutation>;
export type CreateInformationCenterMutationResult = Apollo.MutationResult<CreateInformationCenterMutation>;
export type CreateInformationCenterMutationOptions = Apollo.BaseMutationOptions<CreateInformationCenterMutation, CreateInformationCenterMutationVariables>;
export const UpdateInformationCenterDocument = gql`
    mutation updateInformationCenter($id: String!, $name: String!, $sortNo: Int!) {
  updateInformationCenter(id: $id, input: {name: $name, sortNo: $sortNo})
}
    `;
export type UpdateInformationCenterMutationFn = Apollo.MutationFunction<UpdateInformationCenterMutation, UpdateInformationCenterMutationVariables>;

/**
 * __useUpdateInformationCenterMutation__
 *
 * To run a mutation, you first call `useUpdateInformationCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInformationCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInformationCenterMutation, { data, loading, error }] = useUpdateInformationCenterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      sortNo: // value for 'sortNo'
 *   },
 * });
 */
export function useUpdateInformationCenterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInformationCenterMutation, UpdateInformationCenterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInformationCenterMutation, UpdateInformationCenterMutationVariables>(UpdateInformationCenterDocument, options);
      }
export type UpdateInformationCenterMutationHookResult = ReturnType<typeof useUpdateInformationCenterMutation>;
export type UpdateInformationCenterMutationResult = Apollo.MutationResult<UpdateInformationCenterMutation>;
export type UpdateInformationCenterMutationOptions = Apollo.BaseMutationOptions<UpdateInformationCenterMutation, UpdateInformationCenterMutationVariables>;
export const DeleteInformationCenterDocument = gql`
    mutation deleteInformationCenter($id: String!) {
  deleteInformationCenter(id: $id)
}
    `;
export type DeleteInformationCenterMutationFn = Apollo.MutationFunction<DeleteInformationCenterMutation, DeleteInformationCenterMutationVariables>;

/**
 * __useDeleteInformationCenterMutation__
 *
 * To run a mutation, you first call `useDeleteInformationCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInformationCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInformationCenterMutation, { data, loading, error }] = useDeleteInformationCenterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInformationCenterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInformationCenterMutation, DeleteInformationCenterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInformationCenterMutation, DeleteInformationCenterMutationVariables>(DeleteInformationCenterDocument, options);
      }
export type DeleteInformationCenterMutationHookResult = ReturnType<typeof useDeleteInformationCenterMutation>;
export type DeleteInformationCenterMutationResult = Apollo.MutationResult<DeleteInformationCenterMutation>;
export type DeleteInformationCenterMutationOptions = Apollo.BaseMutationOptions<DeleteInformationCenterMutation, DeleteInformationCenterMutationVariables>;
export const GetInitialsByStoreIdDocument = gql`
    query getInitialsByStoreId($storeId: String!) {
  initialsByStoreId(storeId: $storeId) {
    ...initialData
  }
}
    ${InitialDataFragmentDoc}`;

/**
 * __useGetInitialsByStoreIdQuery__
 *
 * To run a query within a React component, call `useGetInitialsByStoreIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialsByStoreIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialsByStoreIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetInitialsByStoreIdQuery(baseOptions: Apollo.QueryHookOptions<GetInitialsByStoreIdQuery, GetInitialsByStoreIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInitialsByStoreIdQuery, GetInitialsByStoreIdQueryVariables>(GetInitialsByStoreIdDocument, options);
      }
export function useGetInitialsByStoreIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInitialsByStoreIdQuery, GetInitialsByStoreIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInitialsByStoreIdQuery, GetInitialsByStoreIdQueryVariables>(GetInitialsByStoreIdDocument, options);
        }
export type GetInitialsByStoreIdQueryHookResult = ReturnType<typeof useGetInitialsByStoreIdQuery>;
export type GetInitialsByStoreIdLazyQueryHookResult = ReturnType<typeof useGetInitialsByStoreIdLazyQuery>;
export type GetInitialsByStoreIdQueryResult = Apollo.QueryResult<GetInitialsByStoreIdQuery, GetInitialsByStoreIdQueryVariables>;
export const GetInitialByIdDocument = gql`
    query getInitialById($id: String!) {
  initial(id: $id) {
    ...initialData
  }
}
    ${InitialDataFragmentDoc}`;

/**
 * __useGetInitialByIdQuery__
 *
 * To run a query within a React component, call `useGetInitialByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInitialByIdQuery(baseOptions: Apollo.QueryHookOptions<GetInitialByIdQuery, GetInitialByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInitialByIdQuery, GetInitialByIdQueryVariables>(GetInitialByIdDocument, options);
      }
export function useGetInitialByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInitialByIdQuery, GetInitialByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInitialByIdQuery, GetInitialByIdQueryVariables>(GetInitialByIdDocument, options);
        }
export type GetInitialByIdQueryHookResult = ReturnType<typeof useGetInitialByIdQuery>;
export type GetInitialByIdLazyQueryHookResult = ReturnType<typeof useGetInitialByIdLazyQuery>;
export type GetInitialByIdQueryResult = Apollo.QueryResult<GetInitialByIdQuery, GetInitialByIdQueryVariables>;
export const CreateInitialDocument = gql`
    mutation createInitial($storeId: String!, $name: String!, $price: Int!, $description: String) {
  createInitial(
    input: {storeId: $storeId, name: $name, price: $price, description: $description}
  )
}
    `;
export type CreateInitialMutationFn = Apollo.MutationFunction<CreateInitialMutation, CreateInitialMutationVariables>;

/**
 * __useCreateInitialMutation__
 *
 * To run a mutation, you first call `useCreateInitialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInitialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInitialMutation, { data, loading, error }] = useCreateInitialMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      name: // value for 'name'
 *      price: // value for 'price'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateInitialMutation(baseOptions?: Apollo.MutationHookOptions<CreateInitialMutation, CreateInitialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInitialMutation, CreateInitialMutationVariables>(CreateInitialDocument, options);
      }
export type CreateInitialMutationHookResult = ReturnType<typeof useCreateInitialMutation>;
export type CreateInitialMutationResult = Apollo.MutationResult<CreateInitialMutation>;
export type CreateInitialMutationOptions = Apollo.BaseMutationOptions<CreateInitialMutation, CreateInitialMutationVariables>;
export const UpdateInitialDocument = gql`
    mutation updateInitial($id: String!, $name: String!, $price: Int!, $description: String) {
  updateInitial(
    id: $id
    input: {name: $name, price: $price, description: $description}
  )
}
    `;
export type UpdateInitialMutationFn = Apollo.MutationFunction<UpdateInitialMutation, UpdateInitialMutationVariables>;

/**
 * __useUpdateInitialMutation__
 *
 * To run a mutation, you first call `useUpdateInitialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInitialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInitialMutation, { data, loading, error }] = useUpdateInitialMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      price: // value for 'price'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateInitialMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInitialMutation, UpdateInitialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInitialMutation, UpdateInitialMutationVariables>(UpdateInitialDocument, options);
      }
export type UpdateInitialMutationHookResult = ReturnType<typeof useUpdateInitialMutation>;
export type UpdateInitialMutationResult = Apollo.MutationResult<UpdateInitialMutation>;
export type UpdateInitialMutationOptions = Apollo.BaseMutationOptions<UpdateInitialMutation, UpdateInitialMutationVariables>;
export const DeleteInitialDocument = gql`
    mutation deleteInitial($id: String!) {
  deleteInitial(id: $id)
}
    `;
export type DeleteInitialMutationFn = Apollo.MutationFunction<DeleteInitialMutation, DeleteInitialMutationVariables>;

/**
 * __useDeleteInitialMutation__
 *
 * To run a mutation, you first call `useDeleteInitialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInitialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInitialMutation, { data, loading, error }] = useDeleteInitialMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInitialMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInitialMutation, DeleteInitialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInitialMutation, DeleteInitialMutationVariables>(DeleteInitialDocument, options);
      }
export type DeleteInitialMutationHookResult = ReturnType<typeof useDeleteInitialMutation>;
export type DeleteInitialMutationResult = Apollo.MutationResult<DeleteInitialMutation>;
export type DeleteInitialMutationOptions = Apollo.BaseMutationOptions<DeleteInitialMutation, DeleteInitialMutationVariables>;
export const GetItemsByStoreIdDocument = gql`
    query getItemsByStoreId($storeId: String!) {
  itemsByStoreId(storeId: $storeId) {
    ...itemData
    host {
      ...hostData
    }
  }
}
    ${ItemDataFragmentDoc}
${HostDataFragmentDoc}`;

/**
 * __useGetItemsByStoreIdQuery__
 *
 * To run a query within a React component, call `useGetItemsByStoreIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsByStoreIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsByStoreIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetItemsByStoreIdQuery(baseOptions: Apollo.QueryHookOptions<GetItemsByStoreIdQuery, GetItemsByStoreIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemsByStoreIdQuery, GetItemsByStoreIdQueryVariables>(GetItemsByStoreIdDocument, options);
      }
export function useGetItemsByStoreIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemsByStoreIdQuery, GetItemsByStoreIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemsByStoreIdQuery, GetItemsByStoreIdQueryVariables>(GetItemsByStoreIdDocument, options);
        }
export type GetItemsByStoreIdQueryHookResult = ReturnType<typeof useGetItemsByStoreIdQuery>;
export type GetItemsByStoreIdLazyQueryHookResult = ReturnType<typeof useGetItemsByStoreIdLazyQuery>;
export type GetItemsByStoreIdQueryResult = Apollo.QueryResult<GetItemsByStoreIdQuery, GetItemsByStoreIdQueryVariables>;
export const GetItemDocument = gql`
    query getItem($id: String!) {
  item(id: $id) {
    ...itemData
    host {
      ...hostData
    }
  }
}
    ${ItemDataFragmentDoc}
${HostDataFragmentDoc}`;

/**
 * __useGetItemQuery__
 *
 * To run a query within a React component, call `useGetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetItemQuery(baseOptions: Apollo.QueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, options);
      }
export function useGetItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, options);
        }
export type GetItemQueryHookResult = ReturnType<typeof useGetItemQuery>;
export type GetItemLazyQueryHookResult = ReturnType<typeof useGetItemLazyQuery>;
export type GetItemQueryResult = Apollo.QueryResult<GetItemQuery, GetItemQueryVariables>;
export const CreateItemDocument = gql`
    mutation createItem($storeId: String!, $name: String!, $category: ItemCategory!, $price: Int!, $isFreeDrink: Boolean!, $sortNo: Int!, $bottleBack: Int!, $accessControl: [DeviceType!]!) {
  createItem(
    input: {storeId: $storeId, name: $name, category: $category, price: $price, isFreeDrink: $isFreeDrink, sortNo: $sortNo, bottleBack: $bottleBack, accessControl: $accessControl}
  )
}
    `;
export type CreateItemMutationFn = Apollo.MutationFunction<CreateItemMutation, CreateItemMutationVariables>;

/**
 * __useCreateItemMutation__
 *
 * To run a mutation, you first call `useCreateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemMutation, { data, loading, error }] = useCreateItemMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      name: // value for 'name'
 *      category: // value for 'category'
 *      price: // value for 'price'
 *      isFreeDrink: // value for 'isFreeDrink'
 *      sortNo: // value for 'sortNo'
 *      bottleBack: // value for 'bottleBack'
 *      accessControl: // value for 'accessControl'
 *   },
 * });
 */
export function useCreateItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateItemMutation, CreateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateItemMutation, CreateItemMutationVariables>(CreateItemDocument, options);
      }
export type CreateItemMutationHookResult = ReturnType<typeof useCreateItemMutation>;
export type CreateItemMutationResult = Apollo.MutationResult<CreateItemMutation>;
export type CreateItemMutationOptions = Apollo.BaseMutationOptions<CreateItemMutation, CreateItemMutationVariables>;
export const DeleteItemDocument = gql`
    mutation deleteItem($id: String!) {
  deleteItem(id: $id)
}
    `;
export type DeleteItemMutationFn = Apollo.MutationFunction<DeleteItemMutation, DeleteItemMutationVariables>;

/**
 * __useDeleteItemMutation__
 *
 * To run a mutation, you first call `useDeleteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemMutation, { data, loading, error }] = useDeleteItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteItemMutation, DeleteItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteItemMutation, DeleteItemMutationVariables>(DeleteItemDocument, options);
      }
export type DeleteItemMutationHookResult = ReturnType<typeof useDeleteItemMutation>;
export type DeleteItemMutationResult = Apollo.MutationResult<DeleteItemMutation>;
export type DeleteItemMutationOptions = Apollo.BaseMutationOptions<DeleteItemMutation, DeleteItemMutationVariables>;
export const UpdateItemDocument = gql`
    mutation updateItem($id: String!, $name: String!, $category: ItemCategory!, $price: Int!, $sortNo: Int!, $bottleBack: Int!, $accessControl: [DeviceType!]!) {
  updateItem(
    id: $id
    input: {name: $name, category: $category, price: $price, sortNo: $sortNo, bottleBack: $bottleBack, accessControl: $accessControl}
  )
}
    `;
export type UpdateItemMutationFn = Apollo.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      category: // value for 'category'
 *      price: // value for 'price'
 *      sortNo: // value for 'sortNo'
 *      bottleBack: // value for 'bottleBack'
 *      accessControl: // value for 'accessControl'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, options);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
export const BulkUpdateItemsDocument = gql`
    mutation bulkUpdateItems($ids: [String!]!, $category: ItemCategory, $price: Int, $sortNo: Int, $bottleBack: Int, $accessControl: [DeviceType!]) {
  bulkUpdateItems(
    ids: $ids
    input: {category: $category, price: $price, sortNo: $sortNo, bottleBack: $bottleBack, accessControl: $accessControl}
  )
}
    `;
export type BulkUpdateItemsMutationFn = Apollo.MutationFunction<BulkUpdateItemsMutation, BulkUpdateItemsMutationVariables>;

/**
 * __useBulkUpdateItemsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateItemsMutation, { data, loading, error }] = useBulkUpdateItemsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      category: // value for 'category'
 *      price: // value for 'price'
 *      sortNo: // value for 'sortNo'
 *      bottleBack: // value for 'bottleBack'
 *      accessControl: // value for 'accessControl'
 *   },
 * });
 */
export function useBulkUpdateItemsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateItemsMutation, BulkUpdateItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateItemsMutation, BulkUpdateItemsMutationVariables>(BulkUpdateItemsDocument, options);
      }
export type BulkUpdateItemsMutationHookResult = ReturnType<typeof useBulkUpdateItemsMutation>;
export type BulkUpdateItemsMutationResult = Apollo.MutationResult<BulkUpdateItemsMutation>;
export type BulkUpdateItemsMutationOptions = Apollo.BaseMutationOptions<BulkUpdateItemsMutation, BulkUpdateItemsMutationVariables>;
export const AdminLoginDocument = gql`
    mutation adminLogin($account: String!, $password: String!) {
  adminLogin(input: {account: $account, password: $password}) {
    token
    isMaster
    isCollector
    adminName
    type
  }
}
    `;
export type AdminLoginMutationFn = Apollo.MutationFunction<AdminLoginMutation, AdminLoginMutationVariables>;

/**
 * __useAdminLoginMutation__
 *
 * To run a mutation, you first call `useAdminLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdminLoginMutation({
 *   variables: {
 *      account: // value for 'account'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAdminLoginMutation(baseOptions?: Apollo.MutationHookOptions<AdminLoginMutation, AdminLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminLoginMutation, AdminLoginMutationVariables>(AdminLoginDocument, options);
      }
export type AdminLoginMutationHookResult = ReturnType<typeof useAdminLoginMutation>;
export type AdminLoginMutationResult = Apollo.MutationResult<AdminLoginMutation>;
export type AdminLoginMutationOptions = Apollo.BaseMutationOptions<AdminLoginMutation, AdminLoginMutationVariables>;
export const GetPenaltiesDocument = gql`
    query getPenalties {
  penalties {
    ...penaltyData
  }
}
    ${PenaltyDataFragmentDoc}`;

/**
 * __useGetPenaltiesQuery__
 *
 * To run a query within a React component, call `useGetPenaltiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPenaltiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPenaltiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPenaltiesQuery(baseOptions?: Apollo.QueryHookOptions<GetPenaltiesQuery, GetPenaltiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPenaltiesQuery, GetPenaltiesQueryVariables>(GetPenaltiesDocument, options);
      }
export function useGetPenaltiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPenaltiesQuery, GetPenaltiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPenaltiesQuery, GetPenaltiesQueryVariables>(GetPenaltiesDocument, options);
        }
export type GetPenaltiesQueryHookResult = ReturnType<typeof useGetPenaltiesQuery>;
export type GetPenaltiesLazyQueryHookResult = ReturnType<typeof useGetPenaltiesLazyQuery>;
export type GetPenaltiesQueryResult = Apollo.QueryResult<GetPenaltiesQuery, GetPenaltiesQueryVariables>;
export const GetPenaltiesByStoreIdDocument = gql`
    query getPenaltiesByStoreId($storeId: String!) {
  penaltiesByStoreId(storeId: $storeId) {
    ...penaltyData
  }
}
    ${PenaltyDataFragmentDoc}`;

/**
 * __useGetPenaltiesByStoreIdQuery__
 *
 * To run a query within a React component, call `useGetPenaltiesByStoreIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPenaltiesByStoreIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPenaltiesByStoreIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetPenaltiesByStoreIdQuery(baseOptions: Apollo.QueryHookOptions<GetPenaltiesByStoreIdQuery, GetPenaltiesByStoreIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPenaltiesByStoreIdQuery, GetPenaltiesByStoreIdQueryVariables>(GetPenaltiesByStoreIdDocument, options);
      }
export function useGetPenaltiesByStoreIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPenaltiesByStoreIdQuery, GetPenaltiesByStoreIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPenaltiesByStoreIdQuery, GetPenaltiesByStoreIdQueryVariables>(GetPenaltiesByStoreIdDocument, options);
        }
export type GetPenaltiesByStoreIdQueryHookResult = ReturnType<typeof useGetPenaltiesByStoreIdQuery>;
export type GetPenaltiesByStoreIdLazyQueryHookResult = ReturnType<typeof useGetPenaltiesByStoreIdLazyQuery>;
export type GetPenaltiesByStoreIdQueryResult = Apollo.QueryResult<GetPenaltiesByStoreIdQuery, GetPenaltiesByStoreIdQueryVariables>;
export const GetPenaltyDocument = gql`
    query getPenalty($id: String!) {
  penalty(id: $id) {
    ...penaltyData
  }
}
    ${PenaltyDataFragmentDoc}`;

/**
 * __useGetPenaltyQuery__
 *
 * To run a query within a React component, call `useGetPenaltyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPenaltyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPenaltyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPenaltyQuery(baseOptions: Apollo.QueryHookOptions<GetPenaltyQuery, GetPenaltyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPenaltyQuery, GetPenaltyQueryVariables>(GetPenaltyDocument, options);
      }
export function useGetPenaltyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPenaltyQuery, GetPenaltyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPenaltyQuery, GetPenaltyQueryVariables>(GetPenaltyDocument, options);
        }
export type GetPenaltyQueryHookResult = ReturnType<typeof useGetPenaltyQuery>;
export type GetPenaltyLazyQueryHookResult = ReturnType<typeof useGetPenaltyLazyQuery>;
export type GetPenaltyQueryResult = Apollo.QueryResult<GetPenaltyQuery, GetPenaltyQueryVariables>;
export const CreatePenaltyDocument = gql`
    mutation createPenalty($storeId: String!, $name: String!, $price: Int!) {
  createPenalty(input: {storeId: $storeId, name: $name, price: $price})
}
    `;
export type CreatePenaltyMutationFn = Apollo.MutationFunction<CreatePenaltyMutation, CreatePenaltyMutationVariables>;

/**
 * __useCreatePenaltyMutation__
 *
 * To run a mutation, you first call `useCreatePenaltyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePenaltyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPenaltyMutation, { data, loading, error }] = useCreatePenaltyMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      name: // value for 'name'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useCreatePenaltyMutation(baseOptions?: Apollo.MutationHookOptions<CreatePenaltyMutation, CreatePenaltyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePenaltyMutation, CreatePenaltyMutationVariables>(CreatePenaltyDocument, options);
      }
export type CreatePenaltyMutationHookResult = ReturnType<typeof useCreatePenaltyMutation>;
export type CreatePenaltyMutationResult = Apollo.MutationResult<CreatePenaltyMutation>;
export type CreatePenaltyMutationOptions = Apollo.BaseMutationOptions<CreatePenaltyMutation, CreatePenaltyMutationVariables>;
export const UpdatePenaltyDocument = gql`
    mutation updatePenalty($id: String!, $name: String!, $price: Int!) {
  updatePenalty(id: $id, input: {name: $name, price: $price})
}
    `;
export type UpdatePenaltyMutationFn = Apollo.MutationFunction<UpdatePenaltyMutation, UpdatePenaltyMutationVariables>;

/**
 * __useUpdatePenaltyMutation__
 *
 * To run a mutation, you first call `useUpdatePenaltyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePenaltyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePenaltyMutation, { data, loading, error }] = useUpdatePenaltyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useUpdatePenaltyMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePenaltyMutation, UpdatePenaltyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePenaltyMutation, UpdatePenaltyMutationVariables>(UpdatePenaltyDocument, options);
      }
export type UpdatePenaltyMutationHookResult = ReturnType<typeof useUpdatePenaltyMutation>;
export type UpdatePenaltyMutationResult = Apollo.MutationResult<UpdatePenaltyMutation>;
export type UpdatePenaltyMutationOptions = Apollo.BaseMutationOptions<UpdatePenaltyMutation, UpdatePenaltyMutationVariables>;
export const DeletePenaltyDocument = gql`
    mutation deletePenalty($id: String!) {
  deletePenalty(id: $id)
}
    `;
export type DeletePenaltyMutationFn = Apollo.MutationFunction<DeletePenaltyMutation, DeletePenaltyMutationVariables>;

/**
 * __useDeletePenaltyMutation__
 *
 * To run a mutation, you first call `useDeletePenaltyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePenaltyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePenaltyMutation, { data, loading, error }] = useDeletePenaltyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePenaltyMutation(baseOptions?: Apollo.MutationHookOptions<DeletePenaltyMutation, DeletePenaltyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePenaltyMutation, DeletePenaltyMutationVariables>(DeletePenaltyDocument, options);
      }
export type DeletePenaltyMutationHookResult = ReturnType<typeof useDeletePenaltyMutation>;
export type DeletePenaltyMutationResult = Apollo.MutationResult<DeletePenaltyMutation>;
export type DeletePenaltyMutationOptions = Apollo.BaseMutationOptions<DeletePenaltyMutation, DeletePenaltyMutationVariables>;
export const GetStoreTablesByStoreIdDocument = gql`
    query getStoreTablesByStoreId($storeId: String!) {
  storeTablesByStoreId(storeId: $storeId) {
    ...storeTableData
  }
}
    ${StoreTableDataFragmentDoc}`;

/**
 * __useGetStoreTablesByStoreIdQuery__
 *
 * To run a query within a React component, call `useGetStoreTablesByStoreIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreTablesByStoreIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreTablesByStoreIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetStoreTablesByStoreIdQuery(baseOptions: Apollo.QueryHookOptions<GetStoreTablesByStoreIdQuery, GetStoreTablesByStoreIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreTablesByStoreIdQuery, GetStoreTablesByStoreIdQueryVariables>(GetStoreTablesByStoreIdDocument, options);
      }
export function useGetStoreTablesByStoreIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreTablesByStoreIdQuery, GetStoreTablesByStoreIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreTablesByStoreIdQuery, GetStoreTablesByStoreIdQueryVariables>(GetStoreTablesByStoreIdDocument, options);
        }
export type GetStoreTablesByStoreIdQueryHookResult = ReturnType<typeof useGetStoreTablesByStoreIdQuery>;
export type GetStoreTablesByStoreIdLazyQueryHookResult = ReturnType<typeof useGetStoreTablesByStoreIdLazyQuery>;
export type GetStoreTablesByStoreIdQueryResult = Apollo.QueryResult<GetStoreTablesByStoreIdQuery, GetStoreTablesByStoreIdQueryVariables>;
export const GetStoreTableByIdDocument = gql`
    query getStoreTableById($id: String!) {
  storeTable(id: $id) {
    ...storeTableData
  }
}
    ${StoreTableDataFragmentDoc}`;

/**
 * __useGetStoreTableByIdQuery__
 *
 * To run a query within a React component, call `useGetStoreTableByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreTableByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreTableByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStoreTableByIdQuery(baseOptions: Apollo.QueryHookOptions<GetStoreTableByIdQuery, GetStoreTableByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreTableByIdQuery, GetStoreTableByIdQueryVariables>(GetStoreTableByIdDocument, options);
      }
export function useGetStoreTableByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreTableByIdQuery, GetStoreTableByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreTableByIdQuery, GetStoreTableByIdQueryVariables>(GetStoreTableByIdDocument, options);
        }
export type GetStoreTableByIdQueryHookResult = ReturnType<typeof useGetStoreTableByIdQuery>;
export type GetStoreTableByIdLazyQueryHookResult = ReturnType<typeof useGetStoreTableByIdLazyQuery>;
export type GetStoreTableByIdQueryResult = Apollo.QueryResult<GetStoreTableByIdQuery, GetStoreTableByIdQueryVariables>;
export const CreateStoreTableDocument = gql`
    mutation createStoreTable($storeId: String!, $name: String!, $description: String) {
  createStoreTable(
    input: {storeId: $storeId, name: $name, description: $description}
  )
}
    `;
export type CreateStoreTableMutationFn = Apollo.MutationFunction<CreateStoreTableMutation, CreateStoreTableMutationVariables>;

/**
 * __useCreateStoreTableMutation__
 *
 * To run a mutation, you first call `useCreateStoreTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreTableMutation, { data, loading, error }] = useCreateStoreTableMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateStoreTableMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoreTableMutation, CreateStoreTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStoreTableMutation, CreateStoreTableMutationVariables>(CreateStoreTableDocument, options);
      }
export type CreateStoreTableMutationHookResult = ReturnType<typeof useCreateStoreTableMutation>;
export type CreateStoreTableMutationResult = Apollo.MutationResult<CreateStoreTableMutation>;
export type CreateStoreTableMutationOptions = Apollo.BaseMutationOptions<CreateStoreTableMutation, CreateStoreTableMutationVariables>;
export const UpdateStoreTableDocument = gql`
    mutation updateStoreTable($id: String!, $name: String!, $description: String) {
  updateStoreTable(id: $id, input: {name: $name, description: $description})
}
    `;
export type UpdateStoreTableMutationFn = Apollo.MutationFunction<UpdateStoreTableMutation, UpdateStoreTableMutationVariables>;

/**
 * __useUpdateStoreTableMutation__
 *
 * To run a mutation, you first call `useUpdateStoreTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreTableMutation, { data, loading, error }] = useUpdateStoreTableMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateStoreTableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreTableMutation, UpdateStoreTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreTableMutation, UpdateStoreTableMutationVariables>(UpdateStoreTableDocument, options);
      }
export type UpdateStoreTableMutationHookResult = ReturnType<typeof useUpdateStoreTableMutation>;
export type UpdateStoreTableMutationResult = Apollo.MutationResult<UpdateStoreTableMutation>;
export type UpdateStoreTableMutationOptions = Apollo.BaseMutationOptions<UpdateStoreTableMutation, UpdateStoreTableMutationVariables>;
export const DeleteStoreTableDocument = gql`
    mutation deleteStoreTable($id: String!) {
  deleteStoreTable(id: $id)
}
    `;
export type DeleteStoreTableMutationFn = Apollo.MutationFunction<DeleteStoreTableMutation, DeleteStoreTableMutationVariables>;

/**
 * __useDeleteStoreTableMutation__
 *
 * To run a mutation, you first call `useDeleteStoreTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoreTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoreTableMutation, { data, loading, error }] = useDeleteStoreTableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStoreTableMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoreTableMutation, DeleteStoreTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoreTableMutation, DeleteStoreTableMutationVariables>(DeleteStoreTableDocument, options);
      }
export type DeleteStoreTableMutationHookResult = ReturnType<typeof useDeleteStoreTableMutation>;
export type DeleteStoreTableMutationResult = Apollo.MutationResult<DeleteStoreTableMutation>;
export type DeleteStoreTableMutationOptions = Apollo.BaseMutationOptions<DeleteStoreTableMutation, DeleteStoreTableMutationVariables>;
export const GetStoresDocument = gql`
    query getStores {
  stores {
    ...storeData
  }
}
    ${StoreDataFragmentDoc}`;

/**
 * __useGetStoresQuery__
 *
 * To run a query within a React component, call `useGetStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStoresQuery(baseOptions?: Apollo.QueryHookOptions<GetStoresQuery, GetStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoresQuery, GetStoresQueryVariables>(GetStoresDocument, options);
      }
export function useGetStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoresQuery, GetStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoresQuery, GetStoresQueryVariables>(GetStoresDocument, options);
        }
export type GetStoresQueryHookResult = ReturnType<typeof useGetStoresQuery>;
export type GetStoresLazyQueryHookResult = ReturnType<typeof useGetStoresLazyQuery>;
export type GetStoresQueryResult = Apollo.QueryResult<GetStoresQuery, GetStoresQueryVariables>;
export const GetStoreDocument = gql`
    query getStore($id: String!) {
  store(id: $id) {
    ...storeData
    icon
  }
}
    ${StoreDataFragmentDoc}`;

/**
 * __useGetStoreQuery__
 *
 * To run a query within a React component, call `useGetStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStoreQuery(baseOptions: Apollo.QueryHookOptions<GetStoreQuery, GetStoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreQuery, GetStoreQueryVariables>(GetStoreDocument, options);
      }
export function useGetStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreQuery, GetStoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreQuery, GetStoreQueryVariables>(GetStoreDocument, options);
        }
export type GetStoreQueryHookResult = ReturnType<typeof useGetStoreQuery>;
export type GetStoreLazyQueryHookResult = ReturnType<typeof useGetStoreLazyQuery>;
export type GetStoreQueryResult = Apollo.QueryResult<GetStoreQuery, GetStoreQueryVariables>;
export const CreateStoreDocument = gql`
    mutation createStore($name: String!, $icon: String, $description: String, $receiptPrinterIpAddress: String, $serviceFee: Int!, $orderItemLockTime: String, $staffReservationFee: Int) {
  createStore(
    input: {name: $name, icon: $icon, description: $description, receiptPrinterIpAddress: $receiptPrinterIpAddress, serviceFee: $serviceFee, orderItemLockTime: $orderItemLockTime, staffReservationFee: $staffReservationFee}
  )
}
    `;
export type CreateStoreMutationFn = Apollo.MutationFunction<CreateStoreMutation, CreateStoreMutationVariables>;

/**
 * __useCreateStoreMutation__
 *
 * To run a mutation, you first call `useCreateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreMutation, { data, loading, error }] = useCreateStoreMutation({
 *   variables: {
 *      name: // value for 'name'
 *      icon: // value for 'icon'
 *      description: // value for 'description'
 *      receiptPrinterIpAddress: // value for 'receiptPrinterIpAddress'
 *      serviceFee: // value for 'serviceFee'
 *      orderItemLockTime: // value for 'orderItemLockTime'
 *      staffReservationFee: // value for 'staffReservationFee'
 *   },
 * });
 */
export function useCreateStoreMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoreMutation, CreateStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStoreMutation, CreateStoreMutationVariables>(CreateStoreDocument, options);
      }
export type CreateStoreMutationHookResult = ReturnType<typeof useCreateStoreMutation>;
export type CreateStoreMutationResult = Apollo.MutationResult<CreateStoreMutation>;
export type CreateStoreMutationOptions = Apollo.BaseMutationOptions<CreateStoreMutation, CreateStoreMutationVariables>;
export const UpdateStoreDocument = gql`
    mutation updateStore($id: String!, $name: String!, $icon: String, $description: String, $receiptPrinterIpAddress: String, $serviceFee: Int!, $orderItemLockTime: String, $staffReservationFee: Int) {
  updateStore(
    id: $id
    input: {name: $name, icon: $icon, description: $description, receiptPrinterIpAddress: $receiptPrinterIpAddress, serviceFee: $serviceFee, orderItemLockTime: $orderItemLockTime, staffReservationFee: $staffReservationFee}
  )
}
    `;
export type UpdateStoreMutationFn = Apollo.MutationFunction<UpdateStoreMutation, UpdateStoreMutationVariables>;

/**
 * __useUpdateStoreMutation__
 *
 * To run a mutation, you first call `useUpdateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreMutation, { data, loading, error }] = useUpdateStoreMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      icon: // value for 'icon'
 *      description: // value for 'description'
 *      receiptPrinterIpAddress: // value for 'receiptPrinterIpAddress'
 *      serviceFee: // value for 'serviceFee'
 *      orderItemLockTime: // value for 'orderItemLockTime'
 *      staffReservationFee: // value for 'staffReservationFee'
 *   },
 * });
 */
export function useUpdateStoreMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreMutation, UpdateStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreMutation, UpdateStoreMutationVariables>(UpdateStoreDocument, options);
      }
export type UpdateStoreMutationHookResult = ReturnType<typeof useUpdateStoreMutation>;
export type UpdateStoreMutationResult = Apollo.MutationResult<UpdateStoreMutation>;
export type UpdateStoreMutationOptions = Apollo.BaseMutationOptions<UpdateStoreMutation, UpdateStoreMutationVariables>;
export const DeleteStoreDocument = gql`
    mutation deleteStore($id: String!) {
  deleteStore(id: $id)
}
    `;
export type DeleteStoreMutationFn = Apollo.MutationFunction<DeleteStoreMutation, DeleteStoreMutationVariables>;

/**
 * __useDeleteStoreMutation__
 *
 * To run a mutation, you first call `useDeleteStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoreMutation, { data, loading, error }] = useDeleteStoreMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStoreMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoreMutation, DeleteStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoreMutation, DeleteStoreMutationVariables>(DeleteStoreDocument, options);
      }
export type DeleteStoreMutationHookResult = ReturnType<typeof useDeleteStoreMutation>;
export type DeleteStoreMutationResult = Apollo.MutationResult<DeleteStoreMutation>;
export type DeleteStoreMutationOptions = Apollo.BaseMutationOptions<DeleteStoreMutation, DeleteStoreMutationVariables>;
export const GetTitlesDocument = gql`
    query getTitles {
  titles {
    ...titleData
  }
}
    ${TitleDataFragmentDoc}`;

/**
 * __useGetTitlesQuery__
 *
 * To run a query within a React component, call `useGetTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTitlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTitlesQuery(baseOptions?: Apollo.QueryHookOptions<GetTitlesQuery, GetTitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTitlesQuery, GetTitlesQueryVariables>(GetTitlesDocument, options);
      }
export function useGetTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTitlesQuery, GetTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTitlesQuery, GetTitlesQueryVariables>(GetTitlesDocument, options);
        }
export type GetTitlesQueryHookResult = ReturnType<typeof useGetTitlesQuery>;
export type GetTitlesLazyQueryHookResult = ReturnType<typeof useGetTitlesLazyQuery>;
export type GetTitlesQueryResult = Apollo.QueryResult<GetTitlesQuery, GetTitlesQueryVariables>;
export const GetTitleDocument = gql`
    query getTitle($id: String!) {
  title(id: $id) {
    ...titleData
  }
}
    ${TitleDataFragmentDoc}`;

/**
 * __useGetTitleQuery__
 *
 * To run a query within a React component, call `useGetTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTitleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTitleQuery(baseOptions: Apollo.QueryHookOptions<GetTitleQuery, GetTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTitleQuery, GetTitleQueryVariables>(GetTitleDocument, options);
      }
export function useGetTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTitleQuery, GetTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTitleQuery, GetTitleQueryVariables>(GetTitleDocument, options);
        }
export type GetTitleQueryHookResult = ReturnType<typeof useGetTitleQuery>;
export type GetTitleLazyQueryHookResult = ReturnType<typeof useGetTitleLazyQuery>;
export type GetTitleQueryResult = Apollo.QueryResult<GetTitleQuery, GetTitleQueryVariables>;
export const CreateTitleDocument = gql`
    mutation createTitle($name: String!, $sortNo: Int!) {
  createTitle(input: {name: $name, sortNo: $sortNo})
}
    `;
export type CreateTitleMutationFn = Apollo.MutationFunction<CreateTitleMutation, CreateTitleMutationVariables>;

/**
 * __useCreateTitleMutation__
 *
 * To run a mutation, you first call `useCreateTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTitleMutation, { data, loading, error }] = useCreateTitleMutation({
 *   variables: {
 *      name: // value for 'name'
 *      sortNo: // value for 'sortNo'
 *   },
 * });
 */
export function useCreateTitleMutation(baseOptions?: Apollo.MutationHookOptions<CreateTitleMutation, CreateTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTitleMutation, CreateTitleMutationVariables>(CreateTitleDocument, options);
      }
export type CreateTitleMutationHookResult = ReturnType<typeof useCreateTitleMutation>;
export type CreateTitleMutationResult = Apollo.MutationResult<CreateTitleMutation>;
export type CreateTitleMutationOptions = Apollo.BaseMutationOptions<CreateTitleMutation, CreateTitleMutationVariables>;
export const UpdateTitleDocument = gql`
    mutation updateTitle($id: String!, $name: String!, $sortNo: Int!) {
  updateTitle(id: $id, input: {name: $name, sortNo: $sortNo})
}
    `;
export type UpdateTitleMutationFn = Apollo.MutationFunction<UpdateTitleMutation, UpdateTitleMutationVariables>;

/**
 * __useUpdateTitleMutation__
 *
 * To run a mutation, you first call `useUpdateTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTitleMutation, { data, loading, error }] = useUpdateTitleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      sortNo: // value for 'sortNo'
 *   },
 * });
 */
export function useUpdateTitleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTitleMutation, UpdateTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTitleMutation, UpdateTitleMutationVariables>(UpdateTitleDocument, options);
      }
export type UpdateTitleMutationHookResult = ReturnType<typeof useUpdateTitleMutation>;
export type UpdateTitleMutationResult = Apollo.MutationResult<UpdateTitleMutation>;
export type UpdateTitleMutationOptions = Apollo.BaseMutationOptions<UpdateTitleMutation, UpdateTitleMutationVariables>;
export const DeleteTitleDocument = gql`
    mutation deleteTitle($id: String!) {
  deleteTitle(id: $id)
}
    `;
export type DeleteTitleMutationFn = Apollo.MutationFunction<DeleteTitleMutation, DeleteTitleMutationVariables>;

/**
 * __useDeleteTitleMutation__
 *
 * To run a mutation, you first call `useDeleteTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTitleMutation, { data, loading, error }] = useDeleteTitleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTitleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTitleMutation, DeleteTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTitleMutation, DeleteTitleMutationVariables>(DeleteTitleDocument, options);
      }
export type DeleteTitleMutationHookResult = ReturnType<typeof useDeleteTitleMutation>;
export type DeleteTitleMutationResult = Apollo.MutationResult<DeleteTitleMutation>;
export type DeleteTitleMutationOptions = Apollo.BaseMutationOptions<DeleteTitleMutation, DeleteTitleMutationVariables>;
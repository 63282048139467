import { Box } from "@chakra-ui/react";
import { numberFormat } from "@kg-pos/common";
import { HostDataFragment, useGetHostsByTitleIdQuery } from "__generated__/graphql";
import { getHostByTitleRecord } from "common/object/host";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { MainTable } from "components/molecules/main-table";
import { PageTitle } from "components/organisms/page-title";
import { useQueryParams } from "hooks/useQueryParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

const columns = [
  {
    Header: "ホスト名",
    accessor: "name",
  },
  {
    Header: "読み",
    accessor: "yomi",
  },
  {
    Header: "所属店舗",
    accessor: "storeName",
  },
  {
    Header: "役職",
    accessor: "title",
  },
  {
    Header: "ステータス",
    accessor: "status",
  },
  {
    Header: "累計売上",
    accessor: "sales",
    Cell: (props: any) => <div>{numberFormat(props.value)}</div>,
  },
  {
    Header: "累計指名数",
    accessor: "appoints",
    // accessor: "numberOfNominations",
  },
];

export const HostsByTitlePage: React.VFC = () => {
  const { push } = useHistory();
  const { titleId } = useQueryParams() as { titleId: string };
  useTitle("役職別ホスト");
  const { loading, error, data } = useGetHostsByTitleIdQuery({
    variables: {
      titleId,
    },
    fetchPolicy: "cache-and-network",
  });

  const handleClick = (row: HostDataFragment): void => push(`${routeConstants.hosts}/${row.id}`);

  const title = data?.title;
  // 売上順ソートは列名押せばできる
  // const hosts = data?.hostsByTitleId.map(getHostByTitleRecord).sort(sortByhostSales) ?? [];
  const hosts = data?.hostsByTitleId.map(getHostByTitleRecord) ?? [];

  if (loading) return <LoadingLayout />;
  if (error || !title) return <ErrorLayout />;

  return (
    <>
      <PageTitle
        title={`${title.name} ホスト一覧`}
        breadCrumbItems={[
          { label: `${title.name} ホスト一覧`, path: routeConstants.hosts, active: true },
        ]}
      />
      <MainCardContainer>
        <Box pt="4">
          <MainTable data={hosts} columns={columns} handleClick={handleClick} />
        </Box>
      </MainCardContainer>
    </>
  );
};

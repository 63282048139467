import { Box } from "@chakra-ui/react";
import { CreateStoreInput, useCreateStoreMutation } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { PageTitle } from "components/organisms/page-title";
import { StoreForm } from "components/organisms/store-form";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { routeConstants } from "routes";

export const StoreNewPage: React.VFC = () => {
  useTitle("店舗登録");
  const [createStore] = useCreateStoreMutation();
  const submit = async ({
    name,
    description,
    receiptPrinterIpAddress,
    serviceFee,
    orderItemLockTime,
  }: CreateStoreInput) => {
    await createStore({
      variables: {
        name,
        description,
        receiptPrinterIpAddress,
        serviceFee,
        orderItemLockTime,
      },
    });
  };

  return (
    <>
      <PageTitle
        title="店舗登録"
        breadCrumbItems={[
          { label: "店舗", path: "/" },
          {
            label: "店舗登録",
            path: `${routeConstants.stores}/new`,
            active: true,
          },
        ]}
      />

      <MainCardContainer>
        <Box>
          <StoreForm submit={submit} />
        </Box>
      </MainCardContainer>
    </>
  );
};

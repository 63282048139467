import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Button, Text } from "@chakra-ui/react";
import {
  HostResponse,
  useCreateHostMutation,
  useGetHostsByStoreIdQuery,
} from "__generated__/graphql";
import { getHostRecord } from "common/object/host";
import { ErrorLayout } from "components/layouts/error";
import { MainTable } from "components/molecules/main-table";
import { HostForm } from "components/organisms/host-form";
import { StoreContext } from "contexts/store.context";
import { HostSubmit } from "interfaces";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

const columns = [
  {
    Header: "名前",
    accessor: "name",
  },
  {
    Header: "読み",
    accessor: "yomi",
  },
  {
    Header: "ステータス",
    accessor: "status",
  },
  {
    Header: "役職",
    accessor: "title",
  },
  {
    Header: "ナンバー対象者",
    accessor: "hostIsNumber",
  },
  {
    Header: "表示優先度",
    accessor: "sortNo",
  },
];

export const HostLayout: React.VFC = () => {
  const { push } = useHistory();
  const [isForm, setForm] = useState<boolean>(false);
  const { storeId } = useContext(StoreContext);
  const { loading, error, data, refetch } = useGetHostsByStoreIdQuery({
    variables: { storeId },
    fetchPolicy: "cache-and-network",
  });
  const [createHost, { error: createHostError }] = useCreateHostMutation();

  const backToTable = async (): Promise<void> => {
    setForm(false);

    await refetch();
  };

  const handleClick = (row: HostResponse): void =>
    push(`${routeConstants.hosts}/${row.id}?storeId=${storeId}`);

  const submit = async (input: HostSubmit): Promise<void> => {
    await createHost({
      variables: { storeId, ...input },
    });
  };

  if (loading) return null;
  if (error) return <ErrorLayout />;

  const hosts = (data?.hostsByStoreId ?? []).map(getHostRecord);

  if (isForm) {
    return (
      <Box>
        <ArrowBackIcon mb="6" onClick={backToTable} className="cursor-pointer" />
        {createHostError && (
          <Text fontSize="sm" color="red.500" mb={2}>
            ホスト登録に失敗しました。
          </Text>
        )}
        <HostForm submit={submit} backToTable={backToTable} storeId={storeId} />
      </Box>
    );
  }

  return (
    <>
      <Button colorScheme="blue" mb={6} onClick={() => setForm(true)}>
        ホストを登録する
      </Button>
      <MainTable data={hosts} columns={columns} handleClick={handleClick} />
    </>
  );
};

import { DeviceTypeList, numberFormat } from "@kg-pos/common";
import { useDeleteItemMutation, useGetItemQuery } from "__generated__/graphql";
import { getItemRecord } from "common/object/item";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { SingleRead } from "components/molecules/single-read";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { routeConstants } from "routes";

export const ItemPage: React.VFC = () => {
  const id = useParams();
  useTitle("商品詳細");
  const { loading, error, data } = useGetItemQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [deleteItem] = useDeleteItemMutation();

  const item = getItemRecord(data?.item);

  if (loading) return <LoadingLayout />;
  if (error || !item) return <ErrorLayout />;

  const handleDelete = async (): Promise<boolean> => {
    return !!(await deleteItem({ variables: { id } })).data?.deleteItem;
  };

  // ホストと紐付く場内指名を区別する
  const isInsideCallForHost = !!item?.host;

  return (
    <>
      <PageTitle
        title="商品詳細"
        breadCrumbItems={[
          { label: "商品", path: `${routeConstants.stores}/${item.storeId}` },
          {
            label: "商品詳細",
            path: `${routeConstants.items}/${id}`,
            active: true,
          },
        ]}
      />
      <SingleRead
        data={[
          { label: "名前", value: item.name },
          { label: "カテゴリー", value: item.category },
          { label: "価格", value: numberFormat(item.price) },
          { label: "表示優先度", value: item.sortNo },
          { label: "ボトルバック", value: numberFormat(item.bottleBack) },
          {
            label: "アクセス制限",
            value: item.accessControl?.length
              ? item.accessControl.map((dt) => DeviceTypeList[dt]).join(", ")
              : "なし",
          },
        ]}
        editPath={`${routeConstants.items}/edit/${id}`}
        // 場内指名は削除不可にしておく
        {...(!isInsideCallForHost
          ? { deletePath: `${routeConstants.stores}/${item.storeId}` }
          : {})}
        handleDelete={handleDelete}
      />
    </>
  );
};

import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Button } from "@chakra-ui/react";
import { getIdForDisplay, numberFormat } from "@kg-pos/common";
import {
  CustomerResponse,
  useGetCustomersByStoreIdQuery,
  useMergeCustomersMutation,
} from "__generated__/graphql";
import { ErrorLayout } from "components/layouts/error";
import { MainTable } from "components/molecules/main-table";
import { CustomerMergeForm } from "components/organisms/customer-merge-form";
import { StoreContext } from "contexts/store.context";
import { CustomerMergeSubmit } from "interfaces";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

const columns = [
  {
    Header: "顧客ID",
    accessor: "id",
    Cell: (props: any) => <div>{getIdForDisplay(props.value)}</div>,
  },
  {
    Header: "名前",
    accessor: "name",
  },
  {
    Header: "担当",
    accessor: "hostName",
  },
  {
    Header: "売掛金合計",
    accessor: "accountReceivableSum",
    Cell: (props: any) => <div>{numberFormat(props.value)}</div>,
  },
];

export const CustomerLayout: React.VFC = () => {
  const { push } = useHistory();
  const [isForm, setForm] = useState<boolean>(false);
  const { storeId } = useContext(StoreContext);
  const { loading, error, data, refetch } = useGetCustomersByStoreIdQuery({
    variables: { storeId },
    fetchPolicy: "cache-and-network",
  });
  const [mergeCustomers] = useMergeCustomersMutation();

  const backToTable = (): void => {
    setForm(false);
    void refetch();
  };

  const submit = async (input: CustomerMergeSubmit): Promise<void> => {
    await mergeCustomers({ variables: { ...input } });
  };

  const handleClick = (row: CustomerResponse): void =>
    push(`${routeConstants.customers}/${row.id}?storeId=${storeId}`);

  if (loading) return null;
  if (error) return <ErrorLayout />;

  const customers = data?.customersByStoreId ?? [];

  return (
    <>
      {isForm ? (
        <Box>
          <ArrowBackIcon mb="6" onClick={backToTable} className="cursor-pointer" />
          <CustomerMergeForm
            backToTable={backToTable}
            submit={submit}
            data={customers.filter((customer) => !!customer.hostId)}
          />
        </Box>
      ) : (
        <>
          <Button colorScheme="blue" mb={6} onClick={() => setForm(true)}>
            顧客マージ
          </Button>
          <MainTable data={customers} columns={columns} handleClick={handleClick} />
        </>
      )}
    </>
  );
};

import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Button } from "@chakra-ui/react";
import { InputItemCategory, numberFormat } from "@kg-pos/common";
import {
  BulkUpdateItemsInput,
  ItemCategory,
  ItemResponse,
  useBulkUpdateItemsMutation,
  useCreateItemMutation,
  useGetItemsByStoreIdQuery,
} from "__generated__/graphql";
import { getItemRecord } from "common/object/item";
import { ErrorLayout } from "components/layouts/error";
import { MainTable } from "components/molecules/main-table";
import { BulkItemForm } from "components/organisms/bulk-item-form";
import { ItemForm } from "components/organisms/item-form";
import { StoreContext } from "contexts/store.context";
import { ItemSubmit } from "interfaces";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { routeConstants } from "routes";

export const isDefaultBasicItem = (row: any): boolean => {
  return (
    row.original?.category === InputItemCategory[ItemCategory.BASIC_CHARGE] &&
    (row.original?.name === "テーブルチャージ" || row.original?.name === "セット")
  );
};

const columns = [
  {
    Header: "",
    accessor: "checkBox",
    Cell: (props: any) =>
      // セット、テーブルチャージは一括変更されたくないのでチェックボックスなし
      isDefaultBasicItem(props.row) ? null : (
        <input
          className="items-check-boxes"
          type="checkbox"
          name={props.row.original.id}
          item-name={props.row.original.name}
        />
      ),
  },
  {
    Header: "商品名",
    accessor: "name",
  },
  {
    Header: "カテゴリー",
    accessor: "category",
  },
  {
    Header: "価格",
    accessor: "price",
    Cell: (props: any) => <div>{numberFormat(props.value)}</div>,
  },
  {
    Header: "表示優先度",
    accessor: "sortNo",
  },
];

export const ItemLayout: React.VFC = () => {
  const { push } = useHistory();
  const [isForm, setIsForm] = useState<boolean>(false);
  const [isBulkForm, setIsBulkForm] = useState<boolean>(false);
  const [bulkItems, setBulkItems] = useState<ItemResponse[]>([]);
  const { storeId } = useContext(StoreContext);
  const { loading, error, data, refetch } = useGetItemsByStoreIdQuery({
    variables: { storeId },
    fetchPolicy: "cache-and-network",
  });
  const [createItem] = useCreateItemMutation();
  const [bulkUpdateItems] = useBulkUpdateItemsMutation();

  if (loading || !data) return null;
  if (error) return <ErrorLayout />;

  const items = (data?.itemsByStoreId ?? [])
    // 特殊注文を除く
    .filter((item) => item?.category !== ItemCategory.UNIQUE_ORDER)
    .map(getItemRecord);

  const backToTable = async (): Promise<void> => {
    setIsForm(false);
    setIsBulkForm(false);
    await refetch();
  };

  const handleClick = (row: ItemResponse): void => {
    push(`${routeConstants.items}/${row.id}?storeId=${storeId}`);
  };

  const submit = async (input: ItemSubmit): Promise<void> => {
    await createItem({
      variables: { storeId, ...input },
    });
  };

  const bulkUpdateSubmit = async (input: BulkUpdateItemsInput) => {
    await bulkUpdateItems({
      variables: { ids: bulkItems.map((item) => item.id), ...input },
    });
  };

  const updateBulkItems = () => {
    const checkBoxes = document.querySelectorAll(
      'input.items-check-boxes[type="checkbox"]:checked'
    );
    const currentItems: ItemResponse[] = [];
    checkBoxes.forEach((checkBox) => {
      const checkedItemId = checkBox.getAttribute("name");
      const checkedItem = data.itemsByStoreId.find((item) => item?.id === checkedItemId);
      // const itemName = checkBox.getAttribute("item-name");
      if (checkedItem) {
        currentItems.push(checkedItem);
      }
    });
    setBulkItems(currentItems);
  };

  return (
    <>
      {isForm ? (
        <Box>
          <ArrowBackIcon mb="6" onClick={backToTable} className="cursor-pointer" />
          <ItemForm backToTable={backToTable} submit={submit} />
        </Box>
      ) : isBulkForm ? (
        <Box>
          <ArrowBackIcon mb="6" onClick={backToTable} className="cursor-pointer" />
          <BulkItemForm items={bulkItems} backToTable={backToTable} submit={bulkUpdateSubmit} />
        </Box>
      ) : (
        <>
          <Button colorScheme="blue" mb={6} mr={4} onClick={() => setIsForm(true)}>
            商品を登録する
          </Button>
          <Button
            colorScheme="blue"
            mb={6}
            onClick={() => {
              updateBulkItems();
              setIsBulkForm(true);
            }}
          >
            商品を一括編集
          </Button>
          <MainTable data={items} columns={columns} handleClick={handleClick} />
        </>
      )}
    </>
  );
};

// 複数選択チェックボックス
// https://tariqul-islam-rony.medium.com/multiple-checkbox-handling-by-react-js-84b1d49a46c6
// export const MultipleCheckBox: React.VFC<{
//   id: string;
//   name: string;
//   checked: boolean;
//   register: any;
//   textProps?: TextProps;
// }> = ({ id, name, checked, register, textProps }) => (
//   <Text as="label" display="flex" alignItems="center" {...textProps} className="custom-checkbox">
//     {/* <Text fontSize="md" fontWeight="bold" ml={2}>
//       {name}
//     </Text> */}
//     <input type="checkbox" name={id} {...register(id)} defaultChecked={checked} />
//     <span className="checkmark"></span>
//   </Text>
// );

import { numberFormat } from "@kg-pos/common";
import { useDeleteInitialMutation, useGetInitialByIdQuery } from "__generated__/graphql";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { SingleRead } from "components/molecules/single-read";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { routeConstants } from "routes";

export const InitialPage: React.VFC = () => {
  const id = useParams();
  useTitle("テーブル詳細");
  const { loading, error, data } = useGetInitialByIdQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [deleteInitial] = useDeleteInitialMutation();

  const initial = data?.initial;

  if (loading) return <LoadingLayout />;
  if (error || !initial) return <ErrorLayout />;

  const handleDelete = async (): Promise<boolean> => {
    return !!(await deleteInitial({ variables: { id } })).data?.deleteInitial;
  };

  return (
    <>
      <PageTitle
        title="初回詳細"
        breadCrumbItems={[
          { label: "初回", path: `${routeConstants.stores}/${initial.storeId}` },
          {
            label: "初回詳細",
            path: `${routeConstants.initials}/${id}`,
            active: true,
          },
        ]}
      />

      <SingleRead
        data={[
          { label: "名称", value: initial.name },
          { label: "金額", value: numberFormat(initial.price) },
          { label: "説明", value: initial.description },
        ]}
        editPath={`${routeConstants.initials}/edit/${id}`}
        deletePath={`${routeConstants.stores}/${initial.storeId}`}
        handleDelete={handleDelete}
      />
    </>
  );
};

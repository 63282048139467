import { numberFormat } from "@kg-pos/common";
import { StoreResponse, useDeleteStoreMutation } from "__generated__/graphql";
import { SingleRead } from "components/molecules/single-read";
import { AuthContext } from "contexts/current-user.context";
import React, { useContext } from "react";
import { routeConstants } from "routes";

export const StoreLayout: React.VFC<{ store: StoreResponse }> = ({ store }) => {
  const [deleteStore] = useDeleteStoreMutation();
  const currentUser = useContext(AuthContext).currentUser;

  const { id } = store;
  const handleDelete = async (): Promise<boolean> => {
    return !!(await deleteStore({ variables: { id } })).data?.deleteStore;
  };
  if (!currentUser) return null;
  const { isMaster } = currentUser;

  return (
    <SingleRead
      data={[
        { label: "名前", value: store.name },
        // { label: "アイコン", value: store.icon },
        { label: "説明", value: store.description ?? "" },
        { label: "レシートプリンタのIPアドレス", value: store.receiptPrinterIpAddress ?? "" },
        { label: "サービス料", value: `${store.serviceFee}%` },
        {
          label: "注文ロック時間",
          value: `${store.orderItemLockTime?.substring(0, 2)}:${store.orderItemLockTime?.substring(
            2,
            4
          )}`,
        },
        { label: "指名料", value: `${numberFormat(store.staffReservationFee)}` },
      ]}
      editPath={`${routeConstants.stores}/edit/${id}`}
      // 店舗削除は総合管理者のみ
      {...(isMaster ? { deletePath: routeConstants.stores } : {})}
      handleDelete={handleDelete}
      noContainer
    />
  );
};

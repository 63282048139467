import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import { HostResponse, useGetStoresQuery, useGetTitlesQuery } from "__generated__/graphql";
import { getOptions } from "common/util/option";
import { DatePicker } from "components/atoms/date-picker";
import { RequireField } from "components/atoms/require-field";
import { StoreSelect } from "components/molecules/store-select";
import { TitleSelect } from "components/molecules/title-select";
import { hostStatusOptions } from "constant/host";
import { AuthContext } from "contexts/current-user.context";
import { HostSubmit } from "interfaces";
import React, { useContext, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

type HostFormProps = {
  submit: (input: HostSubmit) => Promise<void>;
  backToTable: () => void;
  storeId: string;
  data?: HostResponse;
};

export const HostForm: React.VFC<HostFormProps> = ({ backToTable, submit, storeId, data }) => {
  const { data: getTitles } = useGetTitlesQuery();
  const { data: getStores } = useGetStoresQuery();
  const currentUser = useContext(AuthContext).currentUser;
  const toast = useToast();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: data?.name,
      yomi: data?.yomi,
      hostIsNumber: data?.hostIsNumber === undefined ? false : !data?.hostIsNumber,
      status: data?.status,
      titleId: data?.title?.id,
      title: "",
      startDate: data?.startDate ? new Date(data.startDate) : new Date(),
      sortNo: data?.sortNo,
    },
  });
  const isEdit = useMemo((): boolean => {
    return !!data;
  }, [data]);

  if (!currentUser) return null;
  const { isMaster } = currentUser;

  const onSubmit = async (input: HostSubmit): Promise<void> => {
    const { hostIsNumber } = input;
    await submit({ ...input, hostIsNumber: !hostIsNumber });
    toast({
      title: isEdit ? `${input.name}を編集しました。` : `${input.name}を新規作成しました。`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    backToTable();
  };

  if (!getTitles) return null;
  if (!getStores) return null;
  const titles = getOptions(getTitles?.titles, "name");
  const stores = getOptions(getStores?.stores, "name");
  const currentStore = getStores?.stores.find((store) => store.id === storeId);
  if (!currentStore) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="name" isInvalid={!!errors.name} isRequired maxW="400px">
        <FormLabel>
          名前
          <RequireField />
        </FormLabel>
        <Input
          id="name"
          placeholder="名前を入力してください。"
          {...register("name", {
            required: "名前が必要です。",
            maxLength: {
              value: 100,
              message: "100文字以下で入力してください。",
            },
          })}
        />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl id="yomi" mt={6} isInvalid={!!errors.yomi} maxW="400px">
        <FormLabel>読み</FormLabel>
        <Input
          id="yomi"
          placeholder="読みを入力してください。"
          {...register("yomi", {
            maxLength: {
              value: 100,
              message: "100文字以下で入力してください。",
            },
          })}
        />
        <FormErrorMessage>{errors.yomi?.message}</FormErrorMessage>
      </FormControl>
      {/* 店舗移動が操作できるのは、総合管理者の編集時のみ */}
      {isMaster && isEdit && (
        <StoreSelect register={register} stores={stores} currentStoreId={storeId} errors={errors} />
      )}
      <FormControl id="startDate" mt={6} maxW="400px">
        <FormLabel>入店日</FormLabel>
        <Controller
          name="startDate"
          control={control}
          render={({ field }) => (
            <DatePicker onChange={(date) => field.onChange(date)} selectedDate={field.value} />
          )}
        />
      </FormControl>
      <TitleSelect register={register} titles={titles} errors={errors} />
      <FormControl isInvalid={!!errors.hostIsNumber} mt={6}>
        <Checkbox {...register("hostIsNumber")}>ナンバー対象外</Checkbox>
      </FormControl>
      {isEdit && (
        <FormControl id="status" isInvalid={!!errors.status} mt={6} maxW="400px">
          <FormLabel>
            ステータス
            <RequireField />
          </FormLabel>
          <Select
            placeholder="ステータスを選択してください。"
            {...register("status", {
              required: "ステータスを選択してください。",
            })}
          >
            {hostStatusOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.status?.message}</FormErrorMessage>
        </FormControl>
      )}
      <FormControl id="sortNo" mt={6} maxW="400px" isInvalid={!!errors.sortNo}>
        <FormLabel>
          表示優先度
          <RequireField />
        </FormLabel>
        <Input
          id="sortNo"
          type="number"
          placeholder="表示優先度を入力してください。"
          defaultValue={100}
          {...register("sortNo", {
            required: "表示優先度が必要です。",
            valueAsNumber: true,
            min: {
              value: 0,
              message: "0以上で入力してください。",
            },
            max: {
              value: 1_000_000_000,
              message: "1,000,000,000以下で入力してください。",
            },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.sortNo?.message}</FormErrorMessage>
      </FormControl>
      <Button mt={8} colorScheme="blue" isLoading={isSubmitting} type="submit">
        {isEdit ? "編集する" : "登録する"}
      </Button>
    </form>
  );
};
